import React, { useCallback, useState, useEffect } from "react";
import { InputProps } from "@7egend/web.core.controls/lib/components/input";
import inputI18n from "./i18n";
import { useI18n } from "@7egend/web.core/lib/components/useI18n";
import Styles from "./styles";
import classNames from "classnames";

interface Props extends InputProps {
    /** Input label */
    label?: string;

    /** Additional Text */
    infoText?: string;
}

export const Input: React.FC<Props> = props => {

    const [valid, setValid] = useState<boolean>();
    const [focus, setFocus] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [translate] = useI18n(inputI18n);

    const onChange = useCallback((value: string, name: string, valid: boolean) => {
        // locally check validity of the input
        setValid(valid);
        // send to parent component on change
        props.onChange(value, name, valid);
    }, [props]);

    useEffect(() => {
        if (valid === false && props.required) {
            setShowError(true);
        } else if (showError) {
            setShowError(false);
        }
    }, [props.required, showError, valid]);

    return (
        <Styles.Wrapper className={classNames(props.className, "input__wrapper")}>
            {props.label && (<Styles.Label>{props.label}</Styles.Label>)}
            <Styles.InputWrapper className={`${showError ? "error" : focus ? "focus" : ""}`}>
                <Styles.Input
                    {...props}
                    onChange={onChange}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                />
            </Styles.InputWrapper>
            {showError ?
                (
                    <Styles.Error>{translate("input.field.required")}</Styles.Error>
                ) : (
                    <Styles.Message>{props.infoText}</Styles.Message>
                )
            }
        </Styles.Wrapper>
    );
};
