import styled, { css } from "styled-components";
import { ButtonComponent as CoreButton } from "@7egend/web.core.controls/lib/components/button/button";
import { font, color, mediaQuery, sizes } from "../../styles";

export enum ButtonVariant {
    RedWhite = 1,
    TransparentWhite = 2,
    BlackWhite = 3,
    WhiteBlack = 4,
    TransparentBlack = 5,
    GreyBlack = 6
}

export enum ButtonSize {
    Huge = 1,
    Big = 2,
    Medium = 3,
    Small = 4
}

export interface ButtonStyleProps {
    variant: ButtonVariant;
    size: ButtonSize;
}

export const StyledButton = styled(CoreButton)<ButtonStyleProps>`
    text-decoration: none;
    color: inherit;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    font-family: ${font.primaryEBold};
    text-align: center;
    transition: background-color .2s ease,
                border-color .2s ease,
                color .2s ease;
    letter-spacing: -0.055em;

    &.disabled {
        opacity: 0.6;
    }

    ${({ size }) => size && css`
        ${size === ButtonSize.Huge && css`
            font-size: ${sizes.mobile.s14};
            padding: 16px 40px;

            @media ${mediaQuery.minLaptop} {
                font-size: 1rem;
                padding: 25px 60px;
            }
        `}

        ${size === ButtonSize.Big && css`
            font-size: ${sizes.mobile.s16};
            padding: 30px 64px;

            @media ${mediaQuery.minLaptop} {
                font-size: ${sizes.desktop.s17};
            }
        `}

        ${size === ButtonSize.Medium && css`
            font-size: ${sizes.mobile.s16};
            padding: 16px 40px;

            @media ${mediaQuery.minLaptop} {
                font-size: ${sizes.desktop.s20};
                padding: 20px 60px;
            }
        `}

        ${size === ButtonSize.Small && css`
            font-size: ${sizes.mobile.s16};
            padding: 14px 40px;

            @media ${mediaQuery.minLaptop} {
                font-size: ${sizes.desktop.s16};
            }
        `}
    `}

    ${({ variant }) => variant && css`
        ${(variant === ButtonVariant.RedWhite || variant === ButtonVariant.TransparentWhite || variant === ButtonVariant.BlackWhite) && css`
            color: ${color.white};
        `}

        ${(variant === ButtonVariant.WhiteBlack || variant === ButtonVariant.TransparentBlack) && css`
            color: ${color.black};
        `}

        ${variant === ButtonVariant.RedWhite && css`
            background: ${color.red};
            border: 1px solid ${color.red};

            @media ${mediaQuery.minLaptop} {
                &:hover:not(.disabled) {
                    background-color: ${color.black};
                    border-color: ${color.black};
                }
            }
        `}

        ${variant === ButtonVariant.TransparentWhite && css`
            background: transparent;
            border: 1px solid ${color.white};

            @media ${mediaQuery.minLaptop} { 
                &:hover:not(.disabled) {
                    background-color: ${color.red};
                    border-color: ${color.red};
                }
            }
        `}

        ${variant === ButtonVariant.BlackWhite && css`
            background: ${color.black};
            border: 1px solid ${color.black};

            @media ${mediaQuery.minLaptop} { 
                &:hover:not(.disabled) {
                    background-color: ${color.red};
                    border-color: ${color.red};
                    color: ${color.white};
                }
            }
        `}

        ${variant === ButtonVariant.WhiteBlack && css`
            background: ${color.white};
            border: 1px solid ${color.white};
        `}

        ${variant === ButtonVariant.TransparentBlack && css`
            background: transparent;
            border: 1px solid ${color.greyDarker};

            @media ${mediaQuery.minLaptop} { 
                &:hover:not(.disabled) {
                    background-color: ${color.red};
                    border-color: ${color.red};
                    color: ${color.white};
                }
            }
        `}

        ${variant === ButtonVariant.GreyBlack && css`
            background: ${color.greyLighter};
            border: 1px solid ${color.greyLighter};
            font-family: ${font.primary};
            letter-spacing: 2.4px;

            @media ${mediaQuery.minLaptop} { 
                &:hover {
                    background-color: ${color.red};
                    border-color: ${color.red};
                    color: ${color.white};
                }
            }
        `}
    `}
`;
