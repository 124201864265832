import Base from "./screens/Base";
import loadable from "@loadable/component";

export const routes: any[] = [
  {
    path: ":language/404",
    id: "404",
    component: loadable(
      async () => (await import("./screens/NotFound/NotFound")).NotFound
    ),
  },
  {
    path: "",
    exact: true,
    redirectTo: "/en",
  },
  {
    id: "app",
    path: ":language",
    component: Base,
    routes: [
      {
        path: "",
        exact: true,
        component: loadable(
          async () => (await import("./screens/Home/Home")).Home
        ),
      },
      {
        path: "clubs",
        exact: true,
        component: loadable(
          async () => (await import("./screens/Clubs/Clubs")).Clubs
        ),
      },
      {
        path: "clubs/:id",
        exact: true,
        component: loadable(
          async () => (await import("./screens/Club/Club")).Club
        ),
      },
      {
        path: "contacts/clubs-contacts",
        exact: true,
        component: loadable(
          async () =>
            (await import("./screens/ClubsContacts/ClubsContacts"))
              .ClubsContacts
        ),
      },
      {
        path: "classes",
        exact: true,
        component: loadable(
          async () => (await import("./screens/Classes/Classes")).Classes
        ),
      },
      {
        path: "classes/:id",
        exact: true,
        component: loadable(
          async () =>
            (await import("./screens/ClassDetail/ClassDetail")).ClassDetail
        ),
      },
      {
        path: "personal-training",
        exact: true,
        component: loadable(
          async () =>
            (await import("./screens/PersonalTraining/PersonalTraining"))
              .PersonalTraining
        ),
      },
      {
        path: "timetables",
        exact: true,
        component: loadable(
          async () =>
            (await import("./screens/Timetables/Timetables")).Timetables
        ),
      },
      {
        path: "franchise",
        exact: true,
        component: loadable(
          async () => (await import("./screens/Franchise/Franchise")).Franchise
        ),
      },
      {
        path: "free-day-pass",
        exact: true,
        component: loadable(
          async () =>
            (await import("./screens/FreeDayPass/FreeDayPass")).FreeDayPass
        ),
      },
      {
        path: "contacts/work-with-us",
        exact: true,
        component: loadable(
          async () =>
            (await import("./screens/WorkWithUs/WorkWithUs")).WorkWithUs
        ),
      },
      {
        path: "news/:slug",
        component: loadable(
          async () =>
            (await import("./screens/NewsDetail/NewsDetail")).NewsDetail
        ),
      },
      {
        path: "join-us",
        exact: true,
        component: loadable(
          async () => (await import("./screens/JoinUs/JoinUs")).JoinUs
        ),
      },
      // {
      //   path: "news",
      //   exact: true,
      //   component: loadable(
      //     async () => (await import("./screens/News/News")).News
      //   ),
      // },
      {
        path: "pages/:slug",
        exact: true,
        component: loadable(
          async () => (await import("./screens/Pages/Pages")).Pages
        ),
      },
      {
        path: "stylesheet",
        exact: true,
        component: loadable(
          async () =>
            (await import("./screens/Stylesheet/Stylesheet")).Stylesheet
        ),
      },
      {
        path: "",
        redirectTo: "404",
      },
    ],
  },
];
