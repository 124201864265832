import { DloInput, DloOutput } from "@7egend/web.core";

interface ContactosSeguimientos {
  fecha: string;
  fechaHasta: string;
  hora: string;
  duracion: number;
  descripcion: string;
  idTipoDeOperacionComercial: number;
  idInstalacion: number;
}


interface ContactInputBody {
  nombre: string;
  apellidos: string;
  idInstalacion: number;
  email: string;
  sexo: string;
  movil: string;
  contactosSeguimientos?: ContactosSeguimientos[];
}

export class ContactInput extends DloInput<ContactInputBody> {
  uri = "gyms/api/contacts";
  method = "POST"
}

export class ContactOutput extends DloOutput<ContactInputBody> {}
