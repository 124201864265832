import { DloInput, DloOutput } from "@7egend/web.core";
import { InputBodyGym } from "./gym";

//#region Staff

export interface Staff {
    idTrabajador: number,
    nombre: string,
    apellidos: string,
    accesoStaffEmail: string,
    email: string,
    imagen: string,
}

export class StaffGetAllInput extends DloInput<InputBodyGym> {
    uri = "gyms/api/staff/list"
}

export class StaffGetAllOutput extends DloOutput<Array<Staff>> {}

//#endregion
