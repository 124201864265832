import { DloInput, DloOutput } from "@7egend/web.core";

export interface InputExtendedGym {
    extended?: boolean
}

export interface InputBodyGym {
    installationId: string | number
}

export interface Gym {
  idInstalacion: number,
  region: string,
  urlInstalacion: string,
  longitud: number,
  latitud: number,
  nomInstalacion: string,
  exportacionUrl: string,
  urlAppNoticias: string,
  direccion: string,
  email: string,
  telefono: string,
}

//#region Gyms

export class GymsGetAllInput extends DloInput<InputExtendedGym> {
    uri = "gyms/api/installations/byappkey"
}

export class GymsGetAllOutput extends DloOutput<Gym[]> {}

export class GymGetScheduleInput extends DloInput<InputBodyGym> {
    uri = "gyms/api/installations/schedule"
}

export interface GymSchedule {
    code: number,
    message: string,
    customMessage: {
      id: string,
      parameters: any
    },
    details: string,
    result: {
      horarioGeneral: {
        lunes: [
          {
            inicio: string,
            fin: string,
          }
        ],
        martes: [
          {
            inicio: string,
            fin: string,
          }
        ],
        miercoles: [
          {
            inicio: string,
            fin: string,
          }
        ],
        jueves: [
          {
            inicio: string,
            fin: string,
          }
        ],
        viernes: [
          {
            inicio: string,
            fin: string,
          }
        ],
        sabado: [
          {
            inicio: string,
            fin: string,
          }
        ],
        domingo: [
          {
            inicio: string,
            fin: string,
          }
        ],
        festivo: [
          {
            inicio: string,
            fin: string,
          }
        ]
      },
      horarioEspecifico: string[],
      diasFestivos: string[]
    }
}

export class GymGetScheduleOutput extends DloOutput<GymSchedule> {}

//#endregion
