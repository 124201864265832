import { createGlobalStyle } from "styled-components";
import "./_imports.scss";
import "./_icons.scss";
import { font, color, mediaQuery } from "./variables";

export default createGlobalStyle`
    * {
        box-sizing: border-box;
    }

    html {
        font-size: 24px;

        @media ${mediaQuery.maxLaptopL} {
            font-size: 20px;
        }

        /** mobile */
        @media ${mediaQuery.maxLaptop} {
            font-size: 24px;
        }

        @media ${mediaQuery.maxTablet} {
            font-size: 16px;
        }

        @media ${mediaQuery.maxMobileM} {
            font-size: 14px;
        }
    }

    body {
        font-family: ${font.primary};
        margin: 0;
        padding: 0;
        overflow-x: hidden;
        background-color: ${color.black};
    }

    :root {
        /* Generate CSS Variables with our colors object */
        ${() =>
          Object.entries(color).map(([key, value]) => `--${key}: ${value};`)}
    }

    main {
        position: relative;
        overflow: hidden;
        min-height: 100vh;
    }

    button, input, textarea {
        background-color: transparent;
        outline: none !important;
        outline-color: transparent;
    }

    img, 
    video {
        display: block;
        width: 100%;
        height: auto;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
    }

    p {
        margin-top: 0;
    }

    video {
        outline: none !important;
    }

    div {
        outline: none;
        outline-color: transparent;
    }
    
    a, button {
        outline: none;
        outline-color: transparent;
        text-decoration: none;
        cursor: pointer;
    }

    b, 
    strong {
        font-family: ${font.primaryBold};
    }

    .text-center {
        text-align: center;
    }

    .text-right {
        text-align: right;
    }

    .text-left {
        text-align: left;
    }

    div[class^="gridRow_gridAuto"] { margin-bottom: 0 !important }

    .pages-screen {
        div[class^="gridRow_gridAuto"] { 
            & > div {
                overflow: visible !important
            }
        }
    }

    .preloader {
        position: fixed;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background-color: var(--black);
        will-change: opacity;
        display: flex;
        align-items: center;
        opacity: 0;
        z-index: -1;
        transition: opacity 0.6s linear, z-index 0s linear 1s;
        top: 0;

        &.visible {
            opacity: 1;
            z-index: 101;
            transition: opacity 0.6s linear, z-index 0s linear 0s;
        }

        & > div {
            height: auto !important;
            max-width: 500px;
            top: 50%;
            right: 50%;
            transform: translate3d(50%, -50%, 0);

            @media screen and (max-width: 767px) {
                max-width: 300px;
            }
        }
    }

    main .preloader.visible {
        z-index: 3;
    }
`;
