import React from 'react';
import { Link } from '@7egend/web.core.controls/lib/components/link';
import Styles, { BarStyleProps } from './styles';

interface Props extends BarStyleProps {
    text: string
    link?: {
        text: string
        href: string
    }
}

export const Bar: React.FC<Props> = props => {

    const { text, link, layout } = props;

    if (window.innerWidth < 425 && link) {
        return (
            <Styles.BarLink layout={layout} href={link.href}>
                {text}
            </Styles.BarLink>
        );
    }

    return (
        <Styles.Bar layout={layout}>
            {text}
            {link &&
                <React.Fragment>
                    <Styles.Separator>|</Styles.Separator>
                    <Link href={link.href}>{link.text}</Link>
                </React.Fragment>
            }
        </Styles.Bar>
    );
};
