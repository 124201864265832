
import React from 'react';
import { StyledLink, LinkStyleProps } from './styles';
import { LinkProps } from "@7egend/web.core.controls/lib/components/link/link";

/** Export Link Variant */
export { LinkVariant, LinkSize } from "./styles";

export const Link: React.FC<LinkProps & LinkStyleProps> = props => {

    return (
        <StyledLink
            {...props}
        />
    );
};
