import styled from "styled-components";

export const LoadingWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 50%;
    transform: translateX(50%);
`;

export default {
    LoadingWrapper,
};
