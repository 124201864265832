import React from "react";
import { Props as CoreButtonProps } from "@7egend/web.core.controls/lib/components/button/button";
import { StyledButton, ButtonStyleProps } from "./styles";
import classNames from "classnames";

/** Export Button Variant */
export { ButtonVariant, ButtonSize } from "./styles";

interface Props extends ButtonStyleProps, CoreButtonProps { }

/** TODO: add loading component */
export const Button: React.FC<Props> = props => {

    const { classes, text, onClick, ariaLabel, className } = props;

    return (
        <StyledButton
            {...props}
            className={classNames(className, classes)}
            onClick={onClick}
            ariaLabel={ariaLabel || text}
        ></StyledButton>
    );
};
