import React, { createContext, useState, useEffect } from "react";
import { provisServices } from "../../services/provis";
import { Gym } from "../../services/dlos";
import { useFramework } from "@7egend/web.core/lib/components/useFramework";
import { Club, clubMapping } from "../../data/club";

interface GymContextData {
    /** current gym selected */
    current?: Gym,
    /** list of gyms */
    gyms: Gym[],
    /** set current gym */
    setCurrentGym?: (gym: Gym) => void
    /** find Gym by id in gyms list */
    findGymById: (id: number) => Gym & Club | undefined
}

export const GymsContext = createContext<GymContextData>({
    current: undefined,
    gyms: [],
    findGymById: () => undefined
});

export const GymsProvider = (props: any) => {
    const [currentGym, setCurrentGym] = useState<Gym>();
    const [gyms, setGyms] = useState<Gym[]>([]);
    const fw = useFramework();

    useEffect(() => {
        provisServices.getGyms({ extended: true }).then(gyms => {
            if (gyms.length) {
                setGyms(gyms.filter((element) => element !== null));

                if (!fw.localStorage.get("__current.gym")) {
                    // if not on localStorage
                    setCurrentGym(gyms[0]);
                    fw.localStorage.set("__current.gym", JSON.stringify(gyms[0]));
                } else {
                    // on localStorage
                    setCurrentGym(JSON.parse(fw.localStorage.get("__current.gym")));
                }
            }
        });
    }, [fw.localStorage]);

    // Set current gym and save it on localStorage
    const setContext = React.useCallback((gym: Gym) => {
        setCurrentGym(gym);
        fw.localStorage.set("__current.gym", JSON.stringify(gym));
    }, [fw.localStorage]);

    const findGymById = React.useCallback((id: number) => {
        const gym = gyms.find((element) => element !== null && element.idInstalacion === id);
        const gymData = clubMapping[id];

        return { ...gym, ...gymData } as Gym & Club;
    }, [gyms]);

    const context = React.useMemo<GymContextData>(() => {
        return {
            current: currentGym,
            gyms,
            setCurrentGym: setContext,
            findGymById: findGymById,
        };
    }, [currentGym, gyms, setContext, findGymById]);

    return (
        <GymsContext.Provider value={context}>
            {props.children}
        </GymsContext.Provider>
    );
};
