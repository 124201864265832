import React, { FC, useCallback, useState } from "react";
import { Dropdown } from "../CitySelector/Dropdown";
import { useFramework } from "@7egend/web.core/lib/components/useFramework";
import { useI18n } from "@7egend/web.core/lib/components/useI18n";
import i18n from "../../i18n";
import Styles from "./styles";

interface Props {
    onClick?: () => void
    showLabel?: boolean
}

export const LanguageSelector: FC<Props> = props => {
    const [translate, language] = useI18n(i18n);
    const [dropdownOpen, setDropdownState] = useState(false);
    const fw = useFramework();
    const languages = fw.config.locale.available;

    const changeLanguage = useCallback((lang: string) => {
        fw.i18n.setCurrentLanguage(lang).then(() => window.location.href = `/${lang}`);
        setDropdownState(false);
    }, [fw.i18n]);

    const onClick = useCallback(() => {
        if (props.onClick) {
            props.onClick();
        }

        setDropdownState(!dropdownOpen);
    }, [props, dropdownOpen, setDropdownState]);

    const onClose = useCallback(() => {
        setDropdownState(false);
    }, [setDropdownState]);

    const currentLang = language.getCurrentLanguage();

    return (
        <React.Fragment>
            {props.showLabel &&
                <Styles.LanguageLabel>
                    {translate("language")}
                </Styles.LanguageLabel>
            }

            <Styles.Wrapper open={dropdownOpen} onClick={onClick}>
                <Dropdown
                    onClose={onClose}
                    component={
                        <Styles.SelectLabel open={dropdownOpen}>
                            <div>{currentLang}</div>
                        </Styles.SelectLabel>
                    }>
                    <Styles.List>
                        {languages.map((lang) =>
                            <Styles.ListItem
                                onClick={currentLang === lang ? () => setDropdownState(false) : () => changeLanguage(lang)}
                                active={currentLang === lang}
                                key={`lang-${lang}`}>
                                {translate(`language.${lang.toLowerCase()}`)}
                            </Styles.ListItem>
                        )}
                    </Styles.List>
                </Dropdown>
            </Styles.Wrapper>
        </React.Fragment>
    );
};
