import styled, { css } from "styled-components";
import { font, color, mediaQuery, sizes } from "../../../../styles";

const Button = styled.button<{ active: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  box-shadow: none;
  min-width: 96px;
  max-width: 110px;
  color: ${color.white};

  &::after {
    @media ${mediaQuery.maxLaptop} {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 6px 0 6px;
      border-color: var(--greyDarker) transparent transparent transparent;
      position: absolute;
      top: 12px;
      right: -20px;
    }

    @media ${mediaQuery.maxTablet} {
      top: 8px;
    }
  }

  span {
    font-size: ${sizes.desktop.s15};
    line-height: 1em;
    font-family: ${font.primary};
  }

  i {
    font-size: 18px;
    margin-bottom: 5px;
  }

  @media ${mediaQuery.minLaptop} {
    transition: background-color 0.2s ease;
    height: 70px;

    span {
      font-size: ${sizes.desktop.s15};
    }

    &:hover {
      &,
      i::before {
        color: ${color.red};
      }
      background-color: ${color.black};
    }

    ${({ active }) =>
      active &&
      css`
        &,
        i::before {
          color: ${color.red};
        }
        background-color: ${color.black};
      `}
  }

  @media ${mediaQuery.minLaptopL} {
    height: 80px;

    span {
      font-size: ${sizes.desktop.s14};
    }
  }

  @media screen and (min-width: 1920px) {
    height: 96px;
  }

  @media screen and (min-width: 1900px) {
    font-size: ${sizes.desktop.s20};
  }

  @media ${mediaQuery.maxLaptop} {
    width: auto;
    min-width: 1px;
    max-width: 100%;
    height: auto;
    padding: 0;

    &::after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 6px 0 6px;
      border-color: var(--greyDarker) transparent transparent transparent;
      position: absolute;
      top: 10px;
      right: -20px;
    }

    span {
      text-transform: uppercase;
      font-family: ${font.primaryEBold};
      font-size: ${sizes.mobile.s16};
      letter-spacing: -0.055em;
      display: block;
      margin: 5px 0 0;
      position: relative;
    }

    i {
      display: none;
    }
  }

  @media ${mediaQuery.maxTablet} {
    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      max-width: 50vw;
      display: inline-block;
      text-align: left;
    }
  }
`;

const List = styled.div`
  overflow: auto;
  background-color: ${color.white};
  width: 100%;

  @media ${mediaQuery.maxLaptop} {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    height: calc(100vh - 100px);
    overflow-y: auto;
  }
`;

const ListItem = styled.div`
  position: relative;
  padding: 24px 70px 24px 40px;
  border-bottom: 1px solid var(--greyLight);
  font-size: ${sizes.desktop.s20};
  letter-spacing: -0.046em;
  color: ${color.black};
  text-transform: uppercase;
  font-family: ${font.primaryEBold};
  cursor: pointer;

  i {
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translateY(-50%);

    &::before {
      font-size: 24px;
      color: ${color.red};
    }
  }

  &:hover {
    color: ${color.red};
  }

  &:last-child {
    border-bottom: 0;
  }

  @media ${mediaQuery.minLaptop} {
    font-size: ${sizes.desktop.s15};

    i::before {
      font-size: 20px;
    }
  }

  @media ${mediaQuery.minLaptopL} {
    font-size: ${sizes.desktop.s14};
  }

  @media screen and (min-width: 1900px) {
    font-size: ${sizes.desktop.s20};
  }

  @media ${mediaQuery.maxTablet} {
    font-size: ${sizes.mobile.s18};
    font-family: ${font.primaryBold};
  }
`;

const SelectLabel = styled.label`
  font-family: ${font.primaryBold};
  font-size: ${sizes.mobile.s12};
  letter-spacing: 0;
  text-transform: uppercase;
  color: ${color.greyDarker};

  @media ${mediaQuery.minLaptop} {
    display: none;
  }
`;

export default {
  Button,
  List,
  ListItem,

  // Mobile Selector
  SelectLabel,
};
