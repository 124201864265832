import React, { useState, useEffect } from 'react';
import { Breakpoint } from "@7egend/web.core/lib/components/windowSize/types";
import { CoreApp } from "@7egend/web.core/lib/components/coreApp/coreApp";
import { Config } from "@7egend/web.core/lib/base/config";
import * as Store from "@7egend/web.core/lib/base/store";
import en from "./locale/en.json";
import pt from "./locale/pt.json";
import es from "./locale/es.json";
import { routes } from "./routes";
import { reducer as cmsServices } from "@7egend/web.core.cms/lib/services";

Store.setup();

Store.loadAndRegisterReducer("cms", cmsServices);

function App() {
    // Load configuration
    const [config, setConfig] = useState<Config>();

    useEffect(() => {
        fetch("/config.json")
            .then(response => response.json())
            .then(newConfig => setConfig(newConfig));
    }, [setConfig]);

    if (!config) {
        return null;
    }

    return (
        <CoreApp
            config={{ ...config, routes } as any}
            options={{
                breakpoint: {
                    calculator: () => Breakpoint.Desktop
                },
                i18n: {
                    catalog: {
                        en,
                        pt,
                        es
                    }
                },
            }}
        >
        </CoreApp>
    );
}

export default App;
