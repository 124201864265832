export enum DATE_FORMATS {
  DAY_MONTH_YEAR = "DD-MM-YYYY",
  YEAR_MONTH_DAY = "YYYY-MM-DD",
  WEEKDAY = "dddd",
  WEEKDAY_SMALL = "ddd",
  MONTH = "MMM",
  MONTH_DAY = "MMM D",
  HOUR = "HH:mm A",
  MINUTES = "m",
  HOUR_MINUTES = "Hmm",
}

export enum PLACEHOLDERS {
  IMAGE = "/assets/images/placeholder.jpg",
  ICON = "/assets/images/logo_placeholder.svg",
}

export const GENERAL_CATEGORY_ID: number = 180;

// 2 = web channel
export const POSTS_CHANNEL: string = "2";
// 1 = facebook, 2 = instagram, none = all
export const POSTS_SOURCE: string = "2";
