
export const CLUB_OWNERS = {
    DEFAULT: 1,
    AMAZING_GYMS: 2,
};

export type ClubOwner = typeof CLUB_OWNERS[keyof typeof CLUB_OWNERS]

export interface Club {
    picture?: string
    location?: [number, number]
    owner?: ClubOwner
    email?: string
}

export interface ClubList {
    [key: number]: Club
}

export const commonClubGallery = [];

export const clubMapping: ClubList = {
    // 9566: {
    //     picture: "/assets/images/cr7.png",
    // },
    5165: {
        picture: "/assets/images/clubs/clubs_jaen.jpg",
    },
    5311: {
        picture: "/assets/images/clubs/clubs_maria_de_molina.jpg",
    },
    5320: {
        picture: "/assets/images/clubs/clubs_ciudad_lineal.jpg",
    },
    5566: {
        picture: "/assets/images/clubs/clubs_pardinas.jpg",
    },
    5687: {
        picture: "/assets/images/clubs/clubs_valencia_alfafar.jpg",
    },
    5572: {
        picture: "/assets/images/clubs/clubs_torrelodones.jpg"
    },
    5573: {
        picture: "/assets/images/clubs/clubs_rivas.jpg",
    },
    5574: {
        picture: "/assets/images/clubs/clubs_corazon_de_maria.jpg",
    },
    5576: {
        picture: "/assets/images/clubs/clubs_coruna.jpg",
    },
    5577: {
        picture: "/assets/images/clubs/clubs_badajoz.jpg",
    },
    5666: {
        picture: "/assets/images/clubs/clubs_porto_padrao.jpg",
        owner: CLUB_OWNERS.AMAZING_GYMS
    },
    5667: {
        picture: "/assets/images/clubs/clubs_porto_matosinhos.jpg",
        owner: CLUB_OWNERS.AMAZING_GYMS
    },
    5668: {
        picture: "/assets/images/clubs/clubs_porto_prelada.jpg",
        owner: CLUB_OWNERS.AMAZING_GYMS
    },
    5674: {
        picture: "/assets/images/clubs/clubs_gandia.jpg",
    },
    5704: {
        picture: "/assets/images/clubs/clubs_aveiro.jpg",
    },

    // DEV mapping - Remove in case the id exists on staging
    8165: {
        picture: "/assets/images/clubs/clubs_jaen.jpg",
    },
    9165: {
        picture: "/assets/images/clubs/clubs_maria_de_molina.jpg",
    },
    9320: {
        picture: "/assets/images/clubs/clubs_ciudad_lineal.jpg",
    },
    9566: {
        picture: "/assets/images/clubs/clubs_pardinas.jpg",
    },
    9572: {
        picture: "/assets/images/clubs/clubs_torrelodones.jpg"
    },
    9573: {
        picture: "/assets/images/clubs/clubs_rivas.jpg",
    },
    9574: {
        picture: "/assets/images/clubs/clubs_corazon_de_maria.jpg",
    },
    9576: {
        picture: "/assets/images/clubs/clubs_coruna.jpg",
    },
    9577: {
        picture: "/assets/images/clubs/clubs_badajoz.jpg",
    },
    9666: {
        picture: "/assets/images/clubs/clubs_porto_padrao.jpg",
    },
    9667: {
        picture: "/assets/images/clubs/clubs_porto_matosinhos.jpg",
    },
    9668: {
        picture: "/assets/images/clubs/clubs_porto_prelada.jpg",
    },
    // DEV mapping - end
};
