import styled, { css } from "styled-components";
import { font, color, mediaQuery, sizes } from "../../../../styles";
import { Link } from "@7egend/web.core.controls/lib/components/link";

export interface BarStyleProps {
    layout?: {
        color?: string
        background?: string
    }
}

const Bar = styled.div<BarStyleProps>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: ${font.primaryBold};
    letter-spacing: 0px;
    text-transform: uppercase;
    padding: 10px 24px;
    position: relative;
    z-index: 1;
    text-align: center;
    font-size: ${sizes.mobile.s14};
    
    ${({ layout }) => layout && css`
        ${layout.background === "green" && css`
            background: ${color.green};
        `}

        ${layout.color === "black" && css`
            color: ${color.black};
        `}
    `}

    a {
        color: ${color.black};
    }
    
    a:hover {
        color: ${color.red};
    }

    @media ${mediaQuery.minTablet} {
        padding: 0;
        font-size: ${sizes.desktop.s20};
        height: 50px;
        text-align: left;
    }
`;

const BarLink = styled(Link) <BarStyleProps>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: ${font.primaryBold};
    letter-spacing: 0px;
    text-transform: uppercase;
    padding: 10px 24px;
    position: relative;
    text-align: center;
    font-size: ${sizes.mobile.s14};
    
    ${({ layout }) => layout && css`
        ${layout.background === "green" && css`
            background: ${color.green};
        `}

        ${layout.color === "black" && css`
            color: ${color.black};
        `}
    `}

    a {
        color: ${color.black};
    }
    
    a:hover {
        color: ${color.red};
    }

    @media ${mediaQuery.minTablet} {
        padding: 0;
        font-size: ${sizes.desktop.s20};
        height: 50px;
        text-align: left;
    }
`;

const Separator = styled.span`
    padding: 0 10px;

    @media ${mediaQuery.minTablet} {
        padding: 0 20px;
    }
`;

export default {
    Bar,
    BarLink,
    Separator,
};
