import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import GlobalStyles from "./styles/global";
import { CookiesProvider } from 'react-cookie';

ReactDOM.render(
    <React.Fragment>
        <CookiesProvider>
            <GlobalStyles />
            <App />
        </CookiesProvider>
    </React.Fragment>,
    document.getElementById('root')
);
