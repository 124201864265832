import styled from "styled-components";
import { mediaQuery } from "../../styles";

export const Container = styled.div`
    z-index: 2;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 22px 40px;
    background-color: var(--darker);
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media ${mediaQuery.maxTablet} {
        display: block;
        padding: 25px;
    }

    p {
        color: var(--white);
        font-size: 20px;
        line-height: 26px;
        margin: 0;

        @media ${mediaQuery.maxTablet} {
            font-size: 16px;
            line-height: 20px;
        }
    }

    button {
        padding: 20px 60px;

        @media ${mediaQuery.maxTablet} {
            margin-top: 12px;
            width: 100%;
            padding: 16px 60px;
        }
    }

    a {
        color: var(--white);
        border-bottom: 1px solid;
    }
`;