import React, { useEffect } from "react";
import Header from "../containers/Header/Header";
import Footer from "../containers/Footer/Footer";
import { RouterOutlet } from "@7egend/web.core/lib/components/routerOutlet/routerOutlet";
import { GymsProvider } from "../context/GymsContext/GymsContext";
import { ErrorBoundary } from "../containers/ErrorBoundary/ErrorBoundary";
import { GoogleTagManagerProvider } from "@7egend/web.core.analytics/lib/components/gtm";
import { useFramework } from "@7egend/web.core/lib/components/useFramework";
import { Config } from "../domain/config";
import { LogoRedProvider } from "../context/LogoRedContext";
import { WithLoadingProps, withLoading } from "../components/withLoading";
import Cookiebar from "../components/Cookiebar/Cookiebar";

const Base: React.FC<WithLoadingProps> = props => {
    const fw = useFramework();
    const { setLoaded } = props;

    useEffect(() => {
        setLoaded();
    }, [setLoaded]);

    return (
        <ErrorBoundary>
            <GoogleTagManagerProvider
                id={(fw.config as Config).analytics.gtm}
            >
                <GymsProvider>
                    <LogoRedProvider>
                        <Header />
                        <main>
                            <RouterOutlet id="app" />
                        </main>
                        <Footer />
                        <Cookiebar />
                    </LogoRedProvider>
                </GymsProvider>
            </GoogleTagManagerProvider>
        </ErrorBoundary>
    );
};

export default withLoading(Base);
