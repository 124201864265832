import styled, { css } from "styled-components";
import { Link } from "@7egend/web.core.controls/lib/components/link";
import { color, mediaQuery, font, sizes } from "../../styles";

interface HeaderProps {
  scrolling?: boolean;
}

interface ButtonMenuProps {
  active: boolean;
}


const LogoLink = styled(Link) <{ isLogoRed?: boolean }>`
  display: none;

  @media ${mediaQuery.minLaptop} {
    position: absolute;
    top: 30px;
    left: 30px;
    display: inline-block;
  }

  @media screen and ${mediaQuery.minLaptopL} and ${mediaQuery.maxDesktopS} {
    left: 40px;
  }

  @media ${mediaQuery.minDesktopS} {
    left: 58px;
  }

  #logo {
    transition: all .2s ease;

    @media ${mediaQuery.minLaptop} {
      height: auto;
      width: 50px;
    }

    @media screen and ${mediaQuery.minLaptopL} and ${mediaQuery.maxDesktopS} {
        height: auto;
        width: 70px;
    }

    @media ${mediaQuery.minDesktopS} {
        height: 187px;
        width: 100px;
    }

    ${({ isLogoRed }) => isLogoRed ? css`
      &, * {
        color: var(--red);
        fill: var(--red);
      }
    ` : css`
      &, * {
        color: var(--white);
        fill: var(--white);
      }
    `}
  }
`;

const Header = styled.header<HeaderProps>`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  transition: background-color .2s ease;

  @media ${mediaQuery.maxLaptop} {
    position: sticky;
    background-color: ${color.black};
  }

  ${({ scrolling }) => scrolling && css`
    background-color: ${color.black};
  `}
`;

const Menu = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;

  @media ${mediaQuery.minLaptop} {
    height: 70px;
    padding-left: 105px;
    flex-direction: row-reverse;
  }

  @media ${mediaQuery.minLaptopL} {
    height: 80px;
    padding-left: 210px;
  }

  @media screen and (min-width: 1920px) {
    height: 96px;
  }
`;

const NavigationItem = styled(Link)`
  color: ${color.white};
  text-transform: uppercase;
  font-family: ${font.primaryBold};
  transition: color .2s ease;

  &:hover, &.active {
    color: ${color.red};
  }

  @media ${mediaQuery.minLaptop} {
    margin: 0 16px 0 0;
    text-align: center;
    display: flex;
    align-items: center;
    font-size: ${sizes.desktop.s15};
    flex: 1;
    justify-content: space-around;
  }

  @media screen and (min-width: 1120px) {
    white-space: nowrap;
    flex: 0;
    justify-content: unset;
  }

  @media ${mediaQuery.minLaptopL} {
    font-size: ${sizes.desktop.s16};
    letter-spacing: -0.015em;
  }

  @media screen and (min-width: 1600px) {
    font-size: ${sizes.desktop.s16};
    white-space: nowrap;
  }

  @media screen and (min-width: 1900px) {
    font-size: ${sizes.desktop.s20};
  }

  @media ${mediaQuery.maxLaptop} {
    padding: 10px 0;
    border-bottom: 1px solid ${color.dark};
    width: 100%;
    font-size: ${sizes.mobile.s20};
    line-height: 1.875em;
    opacity: 0;
    position: relative;
    left: 50px;
  }
`;

const SubNavigationItem = styled(Link)`
  position: relative;
  padding: 24px 40px;
  border-bottom: 1px solid ${color.grey};
  font-size: ${sizes.desktop.s20};
  letter-spacing: -0.055em;
  color: ${color.black};
  text-transform: uppercase;
  font-family: ${font.primaryBold};
  cursor: pointer;
  display: block;
  text-align: left;

  @media ${mediaQuery.minLaptop} {
    font-size: ${sizes.desktop.s15};
  }

  @media ${mediaQuery.minLaptopL} {
    font-size: ${sizes.desktop.s16};
    letter-spacing: -0.015em;
  }

  @media screen and (min-width: 1600px) {
    font-size: ${sizes.desktop.s16};
  }

  @media screen and (min-width: 1900px) {
    font-size: ${sizes.desktop.s20};
  }

  &:hover, &.active {
    color: ${color.red};
  }

  &:last-child {
    border-bottom: 0;
  }
`;

const SubNavigation = styled.div`
  position: absolute;
  left: -40px;
  top: 100%;
  background-color: var(--white);
  width: auto;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  min-width: 240px;
`;

const NavigationItemDropdown = styled.div`
  color: ${color.white};
  text-transform: uppercase;
  font-family: ${font.primaryBold};
  transition: color .2s ease;
  position: relative;
  cursor: pointer;

  &:hover, &.active {
    color: ${color.red};
  }

  &:hover {
    & > div {
      opacity: 1;
      visibility: visible;
    }
  }

  @media ${mediaQuery.minLaptop} {
    margin-right: 15px;
    display: flex;
    height: 100%;
    align-items: center;
    font-size: ${sizes.desktop.s15};
  }

  @media ${mediaQuery.minLaptopL} {
    margin-right: 32px;
    font-size: ${sizes.desktop.s16};
    letter-spacing: -0.015em;
  }

  @media screen and (min-width: 1600px) {
    font-size: ${sizes.desktop.s16};
  }

  @media screen and (min-width: 1900px) {
    font-size: ${sizes.desktop.s20};
  }

  @media ${mediaQuery.maxLaptop} {
    padding: 10px 0;
    border-bottom: 1px solid ${color.dark};
    width: 100%;
    font-size: ${sizes.mobile.s20};
    line-height: 1.875em;
    opacity: 0;
    position: relative;
    left: 50px;
  }
`;

const NavigationScrolledWrapper = styled.div`
  display: flex;
  align-items: center;

  @media ${mediaQuery.maxLaptop} {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 24px;
    overflow: hidden;
    overflow-y: auto;
    flex-grow: 1;
  }
`;

const Navigation = styled.nav<{ active: boolean }>`
  display: flex;
  position: fixed;
  height: 100%;
  right: -100%;
  top: 0;
  width: 100%;
  background-color: ${color.black};
  transition: right .1s ease-in-out;

  @media ${mediaQuery.minLaptop} {
    position: initial;
    height: auto;
    width: auto;
    background-color: transparent;
  }

  @media ${mediaQuery.maxLaptop} { 
    flex-direction: column;
    align-items: flex-start;
    top: auto;
    bottom: 0;

    ${({ active }) => active && css`
      right: 0;

      ${NavigationItem} {
        opacity: 1;
        left: 0;
        transition: opacity .4s ease .25s, left .3s ease .25s;
      }
    `}
  }
`;

const Actions = styled.div`
  display: flex;
  flex-grow: 1;
  position: relative;

  .free-day-link,
  .join-link {
    font-size: ${sizes.desktop.s12};
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media ${mediaQuery.minLaptop} {
      font-size: ${sizes.desktop.s15};
      padding: 20px 10px;
      height: 70px;
    }

    @media ${mediaQuery.minLaptopL} {
      font-size: ${sizes.desktop.s16};
      padding: 20px;
      height: 80px; 
    }

    @media screen and (min-width: 1600px) {
      font-size: ${sizes.desktop.s16};
    }

    @media screen and (min-width: 1900px) {
      font-size: ${sizes.desktop.s20};
    }

    @media screen and (min-width: 1920px) {
      height: 96px;
      padding: 30px;
    }

    @media ${mediaQuery.maxLaptop} {
      font-size: ${sizes.desktop.s16};
      flex-grow: 1;
      padding: 10px 0;
      height: auto;
      min-height: 50px;
    }
  }

  .join-link {
    color: ${color.red};

    @media ${mediaQuery.minLaptop} {
      display: flex;
    }
  }

  @media ${mediaQuery.minLaptop} {
    align-items: center;
    flex-grow: 0;
  }
`;

const ActionsDesktop = styled.div`
  display: none;

  @media ${mediaQuery.minLaptop} {
    display: flex;
    align-items: center;
  } 
`;

const ActionsMobileRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ActionsMobile = styled.div`
  display: flex;

  @media ${mediaQuery.minLaptop} {
    display: none;
  }

  @media ${mediaQuery.maxLaptop} {
    align-items: flex-start;
    flex-direction: column;
    background-color: var(--darker);
    padding: 30px 24px 0;
    width: 100%;
    min-height: 170px;
  }

  @media ${mediaQuery.maxTablet} {
    min-height: 0;
    padding: 22px 0 22px;
  }
`;

const UserAccountLink = styled(Link)`
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${color.black};
  color: ${color.white};
  font-size: ${sizes.mobile.s24};
  flex: 0 0 60px;
  position: relative;

  @media ${mediaQuery.minLaptop} {
    width: 50px;
    height: 70px;
    font-size: ${sizes.desktop.s24};
    flex: none;
  }

  @media ${mediaQuery.minLaptopL} {
    width: 75px;
    height: 80px;
    font-size: ${sizes.desktop.s26};
  }

  @media screen and (min-width: 1920px) {
    width: 96px;
    height: 96px;
  }
`;

const ButtonMenu = styled.button<ButtonMenuProps>`
  background-color: ${color.black};
  display: flex;
  flex: 0 0 60px;
  width: 60px;
  border: 0;
  position: relative;
  overflow: hidden;

  .icon-bar {
    width: 21px;
    height: 2px;
    background-color: ${color.white};
    position: absolute;
    transition: transform .1s ease;

    &:first-child {
      top: 50%;
      left: 50%;
      transform: translate(-50%, calc(50% - 11px));
    }

    &:nth-child(2) {
      top: 50%;
      left: 45%;
      transform: translate(-50%, calc(-50% - 1px));
      transition: left .4s ease,
                  opacity .2s ease;
    }

    &:last-child {
      bottom: 50%;
      left: 50%;
      transform: translate(-50%, calc(50% - -8px));
    }

    ${({ active }) => active && css`
      /* hide second element */
      &:nth-child(2) { 
        left: 25%; 
        opacity: 0; 
        transition: left .2s ease,
                    opacity .4s ease;
      }

      &:first-child {
        transform: translate(-50%, -50%) rotate(45deg);
        transition-delay: .2s;
      }

      &:last-child {
        bottom: auto;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        transition-delay: .2s;

      }

    `}
  }

  @media ${mediaQuery.minLaptop} {
    display: none;
  }
`;

const SocialNetworks = styled.div`
  border-top: 1px solid var(--dark);
  width: 100%;
  margin-top: 32px;
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${mediaQuery.maxTablet} {
    display: none;
  }
`;

const SocialLink = styled(Link)`
  margin-right: 40px;

  &:last-child {
    margin-right: 0;
  }
  
  i {
    font-size: 17px;

    &::before {
      color: var(--greyDarker);
    }
  }
`;

export default {
  Header,
  Menu,
  LogoLink,

  /** Navigation */
  Navigation,
  NavigationItem,
  NavigationScrolledWrapper,
  NavigationItemDropdown,
  SubNavigation,
  SubNavigationItem,

  /** Actions */
  Actions,
  ActionsDesktop,
  ActionsMobile,
  ActionsMobileRow,

  /** Redirects the user to other page */
  UserAccountLink,

  /** Button Menu */
  ButtonMenu,

  /** Social Networks */
  SocialNetworks,
  SocialLink,
};
