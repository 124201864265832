import styled from "styled-components";
import { font, mediaQuery, sizes } from "../../styles";
import { Link } from "@7egend/web.core.controls/lib/components/link";
import { Input } from "../../components/Input";
import InputStyles from "../../components/Input/styles";

const ContentRow = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  
  @media ${mediaQuery.minLaptopL} {
    flex-direction: row;
  }
`;

const ContentColumn = styled.div`
  width: 100%;

  @media ${mediaQuery.minLaptopL} { 
    flex: 0 0 33.33%;
    width: 33.33%;
  }

  @media ${mediaQuery.maxLaptopL} {
    margin-bottom: 55px;
  }
`;

const ContentTitle = styled.h3`
  font-family: ${font.primaryEBold};
  color: var(--white);
  text-transform: uppercase;
  text-align: center;
  font-size: ${sizes.mobile.s36};
  line-height: 1.25em;
  letter-spacing: 0;
  margin-bottom: 24px;

  &.links {
    display: none;
  }

  @media ${mediaQuery.minLaptopL} {
    text-align: left;
    font-size: ${sizes.desktop.s26};
    line-height: 0.615em;
    margin-bottom: 48px;

    &.links {
      display: block;
    }
  }
`;

const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const FooterLink = styled(Link)`
  font-family: ${font.primary};
  color: var(--white);
  font-size: ${sizes.mobile.s11};
  line-height: ${sizes.desktop.s30};
  text-transform: uppercase;
  transition: color .2s ease;

  &.active {
    font-family: ${font.primaryBold};

    @media ${mediaQuery.maxTablet} {
      font-family: ${font.primaryEBold};
    }
  }

  &:hover {
    color: var(--red);
  }

  @media ${mediaQuery.minLaptopL} {
    font-size: ${sizes.desktop.s20};
    margin: 0.2em 0;
    line-height: 1rem;
  }
`;

const FooterCopyright = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .rights {
    color: var(--white);
    font-size: ${sizes.mobile.s11};
    line-height: 1.182em;
    font-family: ${font.primary};
    margin-bottom: 8px;
  }

  a {
    font-family: ${font.primary};
    font-size: ${sizes.mobile.s11};
    line-height: 1.455em;
    letter-spacing: 0;
    color: var(--greyLightest);
    transition: color .2s ease;

    b {
      color: var(--white);
    }

    &:hover b {
      color: var(--red);
    }
  }

  @media ${mediaQuery.minLaptopL} {
    margin-top: 100px;
    justify-content: flex-start;
    flex-direction: row;

    .rights {
      font-size: ${sizes.desktop.s14};
      line-height: 1.214em;
      margin-bottom: 0;
    }

    a {
      font-size: ${sizes.desktop.s14};
      margin-left: 80px;
    }
  }
`;

const SocialNetworks = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 48px;

  @media ${mediaQuery.maxLaptopL} {
    justify-content: center;
  }
`;

const SocialLink = styled(Link)`
  margin-right: 40px;

  &:last-child {
    margin-right: 0;
  }
  
  i {
    font-size: 19px;

    &::before {
      color: var(--white);
      transition: color .2s ease;   
    }
  }

  @media ${mediaQuery.minLaptopL} {
    &:hover i::before {
      color: var(--red);
    }
  }
`;

const Newsletter = styled.form`
  position: relative;
  padding: 0;
  margin: 0;
  margin-bottom: 48px;
`;

const Title = styled.h3`
  font-size: ${sizes.mobile.s36};
  line-height: 1em;
  letter-spacing: 0;
  color: var(--white);
  font-family: ${font.primaryEBold};
  text-transform: uppercase;
  margin-bottom: 20px;
  text-align: center;
  
  @media ${mediaQuery.minLaptopL} {
    text-align: right;
    font-size: ${sizes.desktop.s26};
  }
`;

const NewsletterInputs = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  @media ${mediaQuery.minLaptopL} {
    width: 90%;
    margin-left: 10%;
  }
`;

const NewsletterInput = styled(Input)`
  &.input__wrapper {
    padding: 0;
  }

  ${InputStyles.InputWrapper} {
    border-color: rgba(112,112,112, 0.61);
    border-right: 0;

    &.focus {
      border-color: var(--red);
    }
  }

  ${InputStyles.Input} {
    font-size: ${sizes.desktop.s20};
    color: var(--white);
  
    &::placeholder {
      color: var(--white);
    }

    @media ${mediaQuery.maxLaptopL} {
        font-size: ${sizes.mobile.s16};
    }
  }
`;

const NewsletterButton = styled.button`
  transform: skewX(-12deg);
  border: 1px solid var(--red);
  background-color: var(--red);
  width: 73px;
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;

  i {
    display: inline-block;
    transform: skewX(12deg);
  }

  &::after {
    content: "";
    width: 20px;
    height: 100%;
    background-color: var(--red);
    
    position: absolute;
    bottom: -0.5px;
    right: -10px;
    transform: skewX(12deg);

    @media ${mediaQuery.maxLaptopL} {
      height: calc(100% + 2px);
      bottom: -1px;
      right: -7px;
    }
  }

  &:hover {
    background-color: var(--white);
    border: 1px solid var(--white);

    i::before {
      color: var(--red);
    }
    
    &::after { 
      background-color: var(--white);
      border-right: 1px solid var(--white);
      border-bottom: 1px solid var(--white);
     }
  }
`;

export const Message = styled.div<{ error?: boolean }>`
  font-size: 12px;
  line-height: normal;
  font-family: ${font.primaryEBold};
  text-transform: uppercase;
  color: ${({ error }) => error ? "var(--red)" : "var(--white)"};
  letter-spacing: 0;
  text-align: right;
  margin: 10px 0 0;
  
  @media ${mediaQuery.minLaptopL} { 
      font-size: 22px;
      line-height: normal;
      margin: 16px 0 0;
  }
`;

const Contact = styled.div`
  display: flex;
  justify-content: center;

  @media ${mediaQuery.minLaptopL} {
    justify-content: flex-end;
  }
`;

const Logo = styled(Link)`
  display: inline-block;
  margin-bottom: 24px;

  svg {
    width: 100%;
    max-width: 50px;
  }

  @media ${mediaQuery.minLaptopL} {
    display: none;
  }
`;

const Footer = styled.footer`
  padding: 48px 0 40px;

  .contact-us {
    float: right;
  }

  @media ${mediaQuery.minLaptopL} {
    padding: 105px 0 50px;
  }
`;

export default {
  Footer,
  ContentRow,
  ContentColumn,
  ContentTitle,
  FooterLink,
  FooterLinks,
  FooterCopyright,
  SocialNetworks,
  SocialLink,
  Newsletter,
  Title,
  NewsletterInputs,
  NewsletterInput,
  NewsletterButton,
  Contact,
  Logo
};
