import React, { useState, useCallback } from "react";
import Styles, { DropdownAlign, List } from "./styles";
import { useOutside } from "../../../../../hooks/useOutside";

/** export from component the dropdown alignment options */
export { DropdownAlign, List as DropdownList };

interface Props {
  /** alignment of the dropdown */
  align?: DropdownAlign

  /** Component when clicked expands the dropdown list */
  component: React.ComponentClass<any> | React.ComponentType<any> | JSX.Element;

  /** on dropdown click */
  onClick?: (visibility: boolean) => void;

  /* define dropdown list width like 100% | auto | {x}px */
  contentWidth?: string

  closeDropdown?: boolean

  /* on close callback function */
  onClose?: () => void
}

/**
 * # Dropdown
 * 
 * ## How to use:
 * ```js
 * <Dropdown component={<ComponentWrapped />}>
 *   <ListOfComponents />
 * </Dropdown>
 * ```
 */
export const Dropdown: React.FC<Props> = props => {

  const [visible, setVisible] = useState<boolean>(false);
  const wrapperRef = React.useRef(null);
  const [outside] = useOutside(wrapperRef);

  /**
   * Toggle the dropdown visibility
   */
  const toggleDpVisible = useCallback(() => {
    setVisible(!visible);

    // dispatch the onClick event to parent component
    if (props.onClick) {
      props.onClick(!visible);
    }

    if (props.onClose && visible) {
        props.onClose();
    }
  }, [props, visible]);

  /** On Update */
  React.useEffect(() => {
    if (outside) {
      setVisible(false);

      if (props.onClose) {
        props.onClose();
      }

      // dispatch the onClick event to parent component
      if (props.onClick) {
        props.onClick(false);
      }
    }
  }, [outside, props, visible]);

  React.useEffect(() => {
    if (props.closeDropdown) {
      setVisible(false);
    }
  }, [props.closeDropdown]);

  /** if not children */
  if (!(props.component && props.children)) {
    return <div />;
  }

  return (
    <Styles.Wrapper ref={wrapperRef}>
      <div onClick={toggleDpVisible}>
        {props.component}
      </div>
      {visible && (
        <Styles.List onClick={toggleDpVisible} align={props.align} contentWidth={props.contentWidth}>
          {props.children}
        </Styles.List>
      )}
    </Styles.Wrapper>
  );
};
