import React, { useCallback, useState, useEffect } from 'react';
import { useFramework } from '@7egend/web.core/lib/components/useFramework';
import { Post, PostGetAllInput, PostGetAllOutput } from '@7egend/web.core.social/lib/dlos/post';
import Styles from "./styles";
import { POSTS_CHANNEL, POSTS_SOURCE } from '../../data/constants';

export const InstagramFeed: React.FC = () => {
    const { Wrapper, Image, Column } = Styles;
    const [posts, setPosts] = useState<Post[]>();
    const fw = useFramework();

    const fetchPosts = useCallback(async () => {
        const request = new PostGetAllInput();
        request.channelId = POSTS_CHANNEL;
        request.sourceId = POSTS_SOURCE;
        request.body = {
            limit: 6
        };
        const fetch = await fw.dlo.call(request) as PostGetAllOutput;

        if (fetch.body) {
            setPosts(fetch.body);
        }

        return {
            data: fetch.body,
        };
    }, [fw.dlo]);

    useEffect(() => {
        fetchPosts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!posts?.length) {
        return <div />;
    }

    const numberOfPostsCol = posts.length / (window.innerWidth < 768 ? 2 : 3);
    const columns = [numberOfPostsCol, numberOfPostsCol * 2, numberOfPostsCol * 3];

    return (
        <Wrapper>
            {columns.map((column, i) =>
                <Column key={`instagram-col-${i}`}>
                    {posts
                        .filter((element, key) => column > key && key >= column - numberOfPostsCol)
                        .map((post, key) => <Image alt={post.medium?.url} src={post.medium?.image} key={`instagram-${key}`} />)
                    }
                </Column>
            )}
        </Wrapper>
    );
};
