import { DloInput, DloOutput } from "@7egend/web.core";

export interface MembershipPlan {
    idCuota: number
    idInstalacion: number
    nombreCuota: string
    descripcion: string
    idAgrupacion: number
    nombreAgrupacion: string
    metodoDeReserva: number
    idCuotaImporte: number
    idTipoDeCliente: number
    periodicidad: number
    fechaDeInicioCuota: string
    fechaDeFinCuota?: string
    importe: number
    importeCalculado: number
    codigoPromocion?: string
}

export class MembershipPlansGetAllInput extends DloInput<void> {
    uri = "gyms/api/subscriptions/basic"
}

export class MembershipPlansGetAllOutput extends DloOutput<MembershipPlan[]> {}

export interface MembershipExtendedPlans {
    table: MembershipExtendedPlan[]
    table1: MembershipExtendedPlan[] | []
    table2: MembershipExtendedPlan[] | []
    table3: MembershipExtendedPlan[] | []
}

export interface MembershipExtendedPlan {
    idCuotaImporte: number,
    idCuota: number,
    idTipoDeCliente: number,
    periodicidad: number,
    importe: number,
    capacidad: any,
    ocupacionCuotaPeriodicidad: number,
    ocupacionCuota: number,
    noPermitirExcederElNumeroMaximoDePlazas: boolean,
    listaDeEspera: number,
    idInstalacion: number,
    ocupacionExcedida: boolean,
    noPermitida: boolean,
    edadDesde: any,
    edadHasta: any,
    idCuota1: number,
    tipoDeCuota: number,
    idInstalacion1: number,
    nombre: string,
    descripcion: string,
    idAgrupacion: number,
    controlarExcedencias: boolean,
    suplantable: boolean,
    suplantableBonos: boolean,
    capacidad1: any,
    ignorarConfigCobros: boolean,
    idSubcuenta: number,
    numeroDeAccesosPorDia: any,
    esDeUsoFamiliar: boolean,
    idInstalacionPermitida: any,
    noPermitirExcederElNumeroMaximoDePlazas1: boolean,
    controlarHorarioEnSalida: boolean,
    cuotaPorDiasDeUso: boolean,
    minutosDeCortesiaEnLaEntrada: any,
    minutosDeCortesiaEnLaSalida: any,
    edadDesde1: any,
    edadHasta1: any,
    idCuotaPosterior: any,
    activo: boolean,
    metodoDeReserva: number,
    color: any,
    grabarTarjeta: boolean,
    tipoDeTarjeta: number,
    horaFinAperturaTaquilla: any,
    horaFinAperturaTaquillaSabados: any,
    horaFinAperturaTaquillaDomingos: any,
    horaFinAperturaTaquillaFestivos: any,
    clientesALosQueSeAplica: number,
    ts: string,
    codigoGrupoProductosParking: number,
    minutosDesdePrimeraSesion: number,
    minutosEntreSesiones: number,
    numeroDeRele: number,
    idDatosFiscales: number,
    posicionWeb: any,
    esUnaCuota: boolean,
    disponibleParaAsistente: any,
    unSoloAccesoSimultaneo: any,
    estacionAnual: number,
    nombre1: string,
    idAgrupacion1: number,
    numeroDePagosMatricula: number,
    diasPrimerPagoMatricula: number,
    idFormaDePagoMatricula: number,
    numeroDePagosCuotas: number,
    diasPrimerPagoCuota: number,
    idFormaDePagoCuota: number,
    idMatricula: number,
    idInstalacion2: number,
    idDatosFiscales1: number,
    nombre2: string,
    suplantable1: boolean,
    idAgrupacion2: number,
    idSubcuenta1: number,
    activo1: boolean,
    tS1: string,
    idCuotaImporte1: number,
    idCuota2: number,
    idInstalacion3: number,
    idTipoDeCliente1: number,
    periodicidad1: number,
    importe1: number,
    idFormaDeCobro: number,
    idFormaDePago: number,
    porcentajeDeIva: number,
    tipoCont: boolean,
    periodosDeCompromiso: number,
    accesosPorDia: any,
    maximoMinutosPorDia: any,
    fechaDeInicio: string,
    fechaDeFin: any,
    renovable: boolean,
    tipoDeFacturacion: number,
    idTarifa: any,
    idMatricula1: number,
    activo2: boolean,
    tS2: string,
    codigoTipoServicioIBE: any,
    codigoTipoActividadIBE: any,
    codigoActividadIBE: any,
    codigoGrupoTarifaIBE: any,
    codigoTipoTarifaIBE: any,
    codigoTarifaIBE: any,
    tipoDeCambioDeIva: any,
    porcentajeDeCambioDeIva: any,
    importeDeCambioDeIva: any,
    nuevoPorcentajeDeIva: any,
    idCuotaImportePosterior: any,
    numMesesParaCambioCuota: any,
    soloLaPrimeraVez: boolean,
    ajustarHorarioActividadColectivaCuota: boolean,
    disponibleParaAsistente1: any,
    restringirUsoActividadesConfiguradas: boolean,
    idMatriculaImporte: number,
    idMatricula2: number,
    idInstalacion4: number,
    idTipoDeCliente2: number,
    importe2: number,
    idFormaDeCobro1: number,
    idFormaDePago1: number,
    porcentajeDeIva1: number,
    tipoCont1: boolean,
    fechaDeInicio1: string,
    fechaDeFin1: any,
    activo3: boolean,
    tS3: string,
    tipoDeCambioDeIva1: any,
    porcentajeDeCambioDeIva1: any,
    importeDeCambioDeIva1: any,
    nuevoPorcentajeDeIva1: any,
    importeCalculadoMatricula: number,
    importeCalculado: number,
    importeCalculadoMesSiguiente: number,
    numProrrateos: number
}

export interface InputBodyMembership {
    subscriptiongroupid?: number
    ocuppationid?: string
    birthdate?: string
    signupdatetime?: string
    promotionalcoupon?: string
    invitation?: string
    invitationSignature?: string
    installationId?: string
    personid?: string
}

export class MembershipExtendedPlansGetAllInput extends DloInput<InputBodyMembership> {
    uri = "gyms/api/subscriptions/integrations"
}

export class MembershipExtendedPlansGetAllOutput extends DloOutput<MembershipExtendedPlans> {}
