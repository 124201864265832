import React, { useCallback } from 'react';
import { useCookies } from "react-cookie";
import { useI18n } from "@7egend/web.core/lib/components/useI18n";
import i18n from "./locale";
import { Button, ButtonSize, ButtonVariant } from '../Button';
import { Link } from 'react-router-dom';
import { Container } from './styles';

const PRIVACY = {
    cookieConsentKey: "cookie_consent"
};

const Cookiebar: React.FunctionComponent = () => {
    const [translate, language] = useI18n(i18n);
    const [cookies, setCookie] = useCookies([PRIVACY.cookieConsentKey]);

    const onButtonClick = useCallback(() => {
        setCookie(PRIVACY.cookieConsentKey, "1");
    }, [setCookie]);

    if (cookies[PRIVACY.cookieConsentKey]) {
        return null;
    }

    return (
        <Container>
            <div>
                <p>
                    {translate("cookiebar.text1")}
                    <Link to={`/${language.getCurrentLanguage()}/pages/privacy-policy`} target="_blank" rel="noopener noreferrer">
                        {translate("cookiebar.link")}
                    </Link>
                </p>
                <p>{translate('cookiebar.text2')}</p>
            </div>
            <div>
                <Button
                    size={ButtonSize.Huge}
                    variant={ButtonVariant.RedWhite}
                    onClick={onButtonClick}
                >
                    {translate('cookiebar.cta').toString()}
                </Button>
            </div>
        </Container >
    );
};

export default Cookiebar;
