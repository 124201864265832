import React, { FC } from "react";
import { LoadingWrapper } from "./styles";
import Lottie from 'react-lottie';
import loadingBlack from './loading_black.json';
import loadingWhite from './loading_white.json';

export enum LoadingTypes {
    Black = 1,
    White = 2,
};

interface Props {
    background: LoadingTypes
    height?: number | string
    width?: number | string
    isStopped?: boolean
    isPaused?: boolean
    loop?: boolean
    autoplay?: boolean
}

export const Loading: FC<Props> = props => {

    const options = {
        loop: props.loop || true,
        autoplay: props.autoplay || true,
        animationData: props.background === LoadingTypes.White ? loadingWhite : loadingBlack,
    };

    return (
        <LoadingWrapper>
            <Lottie
                width={props.width || 200}
                height={props.height || 200}
                options={options}
                isStopped={props.isStopped || false}
                isPaused={props.isPaused || false}
            />
        </LoadingWrapper>
    );
};
