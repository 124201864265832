import React, { useCallback, useState, useRef, useContext, useMemo } from "react";
import { withMenu, WithMenuProps } from "@7egend/web.core.cms/lib/components/withMenu";
import { useI18n } from "@7egend/web.core/lib/components/useI18n";
import { useFramework } from "@7egend/web.core/lib/components/useFramework";
import { ScrollListener } from "@7egend/web.core/lib/components/scrollListener/scrollListener";
import Styles from "./styles";
import { Bar } from "./components/Bar/Bar";
import { Config } from "../../domain/config";
import { Link, LinkSize, LinkVariant } from "../../components/Link";
import { CitySelector } from "./components";
import { Target } from "@7egend/web.core.controls/lib/components/link";
import { useWindowSize } from "@7egend/web.hooks";
import { withRouter, RouteComponentProps } from "react-router";
import { Menu } from "@7egend/web.core.cms/lib/dlos/menu";
import { ReactComponent as Logo } from "../../logo.svg";
import { LogoRedContext } from "../../context/LogoRedContext";
import { LanguageSelector } from "./components/LanguageSelector";
import { GymsContext } from "../../context/GymsContext";
import { CLUB_OWNERS } from "../../data/club";

interface Props extends WithMenuProps, RouteComponentProps { }

const Header: React.FC<Props> = props => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [translate, , language] = useI18n();
  const [scrolling, setScrolling] = useState<boolean>();
  const [navVisible, setNavVisible] = useState<boolean>(false);
  const [navMargin, setNavMargin] = useState<number>();
  const [menu, setMenu] = useState<Menu[]>();
  const fw = useFramework();
  const windowSize = useWindowSize();
  const ref = useRef<any>(null);
  const { isLogoRed } = useContext(LogoRedContext);
  const { current, findGymById } = useContext(GymsContext);


  const onScroll = useCallback((position: number, event: React.ChangeEvent<Element>) => {
    if (position > 0) {
      setScrolling(true);
    } else if ((props.location.pathname !== `/${language}/`) && (props.location.pathname !== `/${language}`)) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  }, [language, props.location.pathname]);

  /** if user is on contacts page */
  const contactsRoute = useCallback(() => props.location.pathname.split("/"), [props.location.pathname]);

  const href = useMemo(() => {
    if (current) {
        const gym = findGymById(current.idInstalacion);
        const { userAccount } = (fw.config as Config).externalUrl;
        return userAccount[gym?.owner || CLUB_OWNERS.DEFAULT];
    }
}, [current, findGymById, fw.config]);

  /** Handles header height and sets the margin to the nav from the top on Mobile */
  React.useEffect(() => {
    setNavMargin(ref.current.offsetHeight - 3);
  }, [windowSize.width]);

  React.useEffect(() => {
    const root = document.getElementsByTagName("body");

    if (navVisible) {
      root[0].style.overflow = "hidden";
    } else {
      root[0].removeAttribute("style");
    }
  }, [navVisible]);

  React.useEffect(() => {
    setTimeout(() => {
      if ((props.location.pathname !== `/${language}/`) && (props.location.pathname !== `/${language}`)) {
        if (document.getElementsByTagName("main").length && windowSize.width && windowSize.width >= 1024) {
          document.getElementsByTagName("main")[0].style.marginTop = `${ref.current.clientHeight}px`;

        setScrolling(true);
      } else {
        if (document.getElementsByTagName("main").length)
          document.getElementsByTagName("main")[0].removeAttribute("style");

        setScrolling(false);
      }}
    }, 300);
  }, [language, props.location, windowSize.width]);

  React.useEffect(() => {
    if (props.menu.data) {
      let menu: any = [];

      /** In mobile the menu has to be flat */
      if (window.innerWidth < 1024) {
        props.menu.data.forEach(item => {
          if (item.menus && item.menus.length > 0) {
            item.menus.forEach(subItem => {
              menu.push(subItem);
            });
          } else {
            menu.push(item);
          }
        });
      } else {
        menu = props.menu.data;
      }

      setMenu(menu);
    }
  }, [props.menu.data]);

  const goToTop = () => window.scrollTo({ top: 0 });

  const toggleNavVisibility = useCallback(() => {
    if (window.innerWidth < 1024) {
      setNavVisible(!navVisible);
    }
    goToTop();
  }, [navVisible]);

  return (
    <ScrollListener onScroll={onScroll}>
      <Styles.Header ref={ref} scrolling={scrolling}>
        <Bar text={translate("bar.covid.message")} link={{ href: translate("bar.covid.link.url"), text: translate("bar.covid.link.text") }} layout={{ background: "green", color: "black" }} />

        <Styles.Menu>
          <Styles.LogoLink isLogoRed={isLogoRed} href={"/"} onClick={goToTop}>
            <Logo id="logo" />
          </Styles.LogoLink>

          <Styles.Actions>
            <Styles.ActionsDesktop>
              <LanguageSelector />
              <CitySelector />
            </Styles.ActionsDesktop>
            <Link onClick={() => {
              if (navVisible) {
                setNavVisible(false);
              }
              goToTop();
            }} className="free-day-link" href="free-day-pass" size={LinkSize.Medium} variant={LinkVariant.RedWhite}>{translate("get.freeday.pass")}</Link>
            <Link onClick={() => {
              if (navVisible) {
                setNavVisible(false);
              }
              goToTop();
            }} className="join-link" href="join-us" size={LinkSize.Medium} variant={LinkVariant.WhiteBlack}>{translate("join.now")}</Link>
            <Styles.UserAccountLink href={href || ''} target={Target.blank}>
              <i className="icon-user"></i>
            </Styles.UserAccountLink>
            <Styles.ButtonMenu active={navVisible} onClick={toggleNavVisibility}>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </Styles.ButtonMenu>
          </Styles.Actions>

          <Styles.Navigation
            active={navVisible}
            style={{
              height: windowSize.width && windowSize.width < 1024 ? `calc(100% - ${navMargin}px)` : ""
            }}
          >
            <Styles.NavigationScrolledWrapper>
              {windowSize.width && windowSize.width < 1024 && (
                <Styles.NavigationItem
                  onClick={toggleNavVisibility}
                  key="home"
                  href="/"
                  // @ts-ignore
                  exact={true}
                >{translate("home")}</Styles.NavigationItem>
              )}

              {(menu && menu.length > 0) && menu.map(item => {
                if (item.published) {
                  if (!item.menus?.length) {
                    return (
                      <Styles.NavigationItem
                        onClick={toggleNavVisibility}
                        key={item.id || item.path}
                        href={item.path}
                      >{item.name}</Styles.NavigationItem>
                    );
                  }

                  return (
                    <Styles.NavigationItemDropdown
                      key={item.id || item.path}
                      className={contactsRoute().find(route => route === "contacts") ? "active" : ""}
                    >
                      {item.name}
                      <Styles.SubNavigation>
                        {item.menus.map(subItem => (
                          <Styles.SubNavigationItem
                            key={subItem.id || subItem.path}
                            href={subItem.path}
                            onClick={toggleNavVisibility}
                            className={contactsRoute().find(route => route === subItem.path) ? "active" : ""}
                          >
                            <div>{subItem.name}</div>
                          </Styles.SubNavigationItem>
                        ))}
                      </Styles.SubNavigation>
                    </Styles.NavigationItemDropdown>
                  );
                }

                return null;
              })}
            </Styles.NavigationScrolledWrapper>

            <Styles.ActionsMobile>
              <Styles.ActionsMobileRow>
                <div className="col-8" onClick={() => setNavVisible(false)}>
                  <CitySelector />
                </div>
                <div className="col-4">
                  <LanguageSelector onClick={() => setNavVisible(false)} showLabel />
                </div>
              </Styles.ActionsMobileRow>
              <Styles.ActionsMobileRow>
                <Styles.SocialNetworks>
                  {(fw.config as Config).social.facebook && (
                    <Styles.SocialLink href={(fw.config as Config).social.facebook} target={Target.blank}><i className="icon-facebook"></i></Styles.SocialLink>
                  )}

                  {(fw.config as Config).social.instagram && (
                    <Styles.SocialLink href={(fw.config as Config).social.instagram} target={Target.blank}><i className="icon-instagram"></i></Styles.SocialLink>
                  )}

                  {(fw.config as Config).social.twitter && (
                    <Styles.SocialLink href={(fw.config as Config).social.twitter} target={Target.blank}><i className="icon-twitter"></i></Styles.SocialLink>
                  )}

                  {(fw.config as Config).social.snapchat && (
                    <Styles.SocialLink href={(fw.config as Config).social.snapchat} target={Target.blank}><i className="icon-snapchat"></i></Styles.SocialLink>
                  )}
                </Styles.SocialNetworks>
              </Styles.ActionsMobileRow>
            </Styles.ActionsMobile>
          </Styles.Navigation>
        </Styles.Menu>
      </Styles.Header>
    </ScrollListener >
  );
};

export default withMenu(withRouter(Header));
