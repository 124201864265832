import { DloInput, DloOutput } from "@7egend/web.core";

//#region Classes
interface InputBodyClassesPublic {
  idInstallation?: string | number;
  startdatetime: string;
  endDatetime: string;
}

interface InputBodyClassesSimplifieldList {
  idInstallation?: string | number;
}

export interface ScheduleClass {
  id: number;
  nombre: string;
  horaInicio: string;
  horaFin: string;
  zona: number;
  nombreZona: string;
  idImagen: number;
  nombreAgrupacion: string;
  idActividadColectiva: number;
  idAgrupacion: number;

  fechaModificacionImagen?: string;
  estaReservadaPorLaPersona?: boolean;
  color?: number;
  razonReserva?: string;
  plazas?: number;
  nombrePlaza?: string | null;
  capacidad?: number;
  reservasHechas?: number;
  idArticulo?: number | null;
  tieneImagen?: boolean;
  idBono?: number | null;
  idTrabajador?: number;
  nombreTrabajador?: string;
  apellidosTrabajador?: string;
  trabajadorTieneImagen?: boolean;
  minutosDeAntelacionParaApertura?: string | null;
  minutosDeRetrasoParaCierre?: string | null;
  horaDeApertura?: string | null;
  horaDeCierre?: string | null;
  permiteListaDeEspera?: boolean;
  maximoClaseListaDeEspera?: number;
  posicionListaDeEspera?: number;
  caducada?: boolean;
  colorIcono?: number;
  colorIconoHex?: string | null;
  icono?: string | null;
  colorFondoIntensidad?: number;
  valorIntensidad?: number;
  valorIntensidadTotal?: number;
  descripcion?: string | null;
}

export interface ClassesSimplified {
  id?: number;
  idActividadColectiva: number;
  description?: string;
  nombreActividadColectiva: string;
  imagen: string;
  agrupacion: string;
  idAgrupacion: number;
}

export interface ClassesCompleteItem extends ClassesSimplified {
  idInstalacion: number;
}

export interface DayClasses {
  fecha: string;
  clases: ScheduleClass[];
}

export class ClassesGetAllInput extends DloInput<InputBodyClassesPublic> {
  uri = "gyms/api/collectiveclasses";
}

export class ClassesGetAllOutput extends DloOutput<ScheduleClass[]> {}

export class ClassesPublicGetAllInput extends DloInput<InputBodyClassesPublic> {
  uri = "gyms/api/collectiveclasses/public";
}

export class ClassesPublicGetAllOutput extends DloOutput<DayClasses[]> {}

export class ClassesSimplifiedGetAllInput extends DloInput<InputBodyClassesSimplifieldList> {
  uri = "gyms/api/collectiveclasses/simple/image";
}

export class ClassesSimplifiedGetAllOutput extends DloOutput<
  ClassesSimplified[]
> {}

// Get complete list of classes from all gyms
export class ClassesCompleteListGetAllInput extends DloInput {
  uri = "gyms/api/collectiveclasses/simple/list";
}

export class ClassesCompleteListGetAllOutput extends DloOutput<
  ClassesCompleteItem[]
> {}
//#endregion
