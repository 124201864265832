import React, { createContext, createRef, useCallback, useEffect, useState } from "react";
import { ScrollListener } from "@7egend/web.core/lib/components/scrollListener/scrollListener";

interface LogoRedContextInterface {
  /** section ref */
  ref?: any;

  /** Sets by default the logo as red */
  setLogoRed: () => void;

  /** returns true/false if logo is red */
  isLogoRed?: boolean
}

export const LogoRedContext = createContext<LogoRedContextInterface>({
  ref: null,
  setLogoRed: () => undefined,
  isLogoRed: false
});

export const LogoRedProvider = (props: any) => {
  const [isLogoRed, setLogoRed] = useState<boolean>();
  const ref = createRef<any>();

  const onScroll = useCallback((position: number, event: React.ChangeEvent<Element>) => {
    const headerRef = document.getElementsByTagName("header");

    if (ref && ref.current && headerRef!.length > 0) {

      if ((position >= (ref.current.offsetTop - headerRef[0].offsetHeight)) && (position < (ref.current.offsetTop + ref.current.offsetHeight - headerRef[0].offsetHeight))) {
        setLogoRed(true);
      } else if (position < (ref.current.offsetTop - headerRef[0].offsetHeight)) {
        setLogoRed(false);
      } else {
        setLogoRed(false);
      }
    }
  }, [ref]);

  const setLogoRedContext = useCallback(() => {
    setLogoRed(true);
  }, []);

  useEffect(() => {
    if (!ref.current) {
      setLogoRed(false);
    }
  }, [ref]);

  return (
    <ScrollListener onScroll={onScroll}>
      <LogoRedContext.Provider value={{ ref, setLogoRed: setLogoRedContext, isLogoRed }}>
        {props.children}
      </LogoRedContext.Provider>
    </ScrollListener>
  );
};
