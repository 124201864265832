import React, { useState, useContext } from "react";
import Styles from "./styles";
import { Dropdown } from "./Dropdown";
import { color } from "../../../../styles";
import { useI18n } from "@7egend/web.core/lib/components/useI18n";
import { GymsContext } from "../../../../context/GymsContext";
import { Gym } from "../../../../services/dlos";

/**
 * # City Selector Component
 * 
 * This component fetchs the list of available locations and sets the active one.
 */
export const CitySelector: React.FC = props => {
  const [translate] = useI18n();
  const [buttonActive, setButtonActive] = useState<boolean>(false);
  const { gyms, current, setCurrentGym } = useContext(GymsContext);
  const [dropClosed, setDropClosed] = useState<boolean>(false);

  /**
   * Sets the new location selected by the user
   */
  const changeActiveGym = React.useCallback((gym: Gym) => {
    setCurrentGym && setCurrentGym(gym);
    setDropClosed(true);
  }, [setCurrentGym]);

  /**
   * On Dropdown Click
   * 
   * Used to catch the visibility of the dropdown and use it on Button to have the "active" style
   */
  const onDropdownClick = React.useCallback((visibility: boolean) => {
    setButtonActive(visibility);
  }, []);

  React.useEffect(() => {
    if (dropClosed) {
      setDropClosed(false);
    }
  }, [dropClosed]);

  if ((!gyms && !current) || !gyms.length) {
    return <div />;
  }

  return (
    <React.Fragment>
      <Dropdown
        closeDropdown={dropClosed}
        onClick={onDropdownClick}
        component={
          <React.Fragment>
            <Styles.SelectLabel>
              {translate("location")}
            </Styles.SelectLabel>
            <Styles.Button active={buttonActive}>
              <i className="icon-location"></i>
              <span>
                {current?.nomInstalacion}
              </span>
            </Styles.Button>
          </React.Fragment>
        }>
        <Styles.List onClick={e => e.stopPropagation()}>
          {
            current && (<Styles.ListItem
              style={{ color: "var(--red)" }}
              key={current.idInstalacion}
            >
              <span>{current.nomInstalacion}</span>
              <i className="icon-location"></i>
            </Styles.ListItem>)
          }
          {
            current && gyms.filter(filterGym => filterGym.idInstalacion !== current.idInstalacion).map(gym => {
              if (!gym) {
                return null;
              }

              return (
                <Styles.ListItem
                  style={{ color: current.idInstalacion === gym.idInstalacion ? color.red : "" }}
                  onClick={() => changeActiveGym(gym)}
                  key={gym.idInstalacion}
                >
                  <span>{gym.nomInstalacion}</span>
                  {current.idInstalacion === gym.idInstalacion && (<i className="icon-location"></i>)}
                </Styles.ListItem>
              );
            })
          }
        </Styles.List>
      </Dropdown>
    </React.Fragment>
  );
};
