import styled from "styled-components";
import { mediaQuery } from "../../styles";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const Image = styled.img`
  object-fit: contain;
  padding: 5px;

  @media ${mediaQuery.maxTablet} {
    height: 100%;
    align-self: center;
  }
`;

const Column = styled.div`
  flex: 33.33%;

  @media ${mediaQuery.maxTablet} {
    flex: 50%;
  }
`;

export default {
  Wrapper,
  Image,
  Column,
};
