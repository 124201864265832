import styled, { css } from "styled-components";
import { mediaQuery } from "../../../../../styles";

export enum DropdownAlign {
  Left = "left",
  Right = "right"
}

interface ListProps {
  align?: DropdownAlign
  contentWidth?: string
}

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;

  @media ${mediaQuery.maxLaptop} {
    position: inherit;
  }
`;

export const List = styled.div<ListProps>`
  position: absolute;
  top: 100%;
  height: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: var(--white);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--grey) ;
    border-radius: 6px;
  }

  @media ${mediaQuery.minLaptop} {
    min-width: 360px;
    max-height: 510px;
    height: 360px;
    overflow: hidden;
    overflow-y: auto;

    ${({ align }) => align ? css`
        ${align === DropdownAlign.Left && css`
          left: 0;
        `}

        ${align === DropdownAlign.Right && css`
          right: 0;
        `}
      ` : css`
        right: 0;
      `
    }

    ${({ contentWidth }) => contentWidth && css`
        min-width: ${contentWidth};

        ${contentWidth === "125%" && css`
          left: -25%;
        `}
      `}
    }

  @media ${mediaQuery.maxLaptop} { 
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);

    position: fixed;
    top: 0;
    left: 0;
    z-index: 300;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`;

export default {
  Wrapper,
  List
};
