import styled from "styled-components";
import { Input as CoreInput } from "@7egend/web.core.controls/lib/components/input";
import { color, font, mediaQuery, sizes } from "../../styles";

const Input = styled(CoreInput)`
    border: 0;
    width: calc(100% - 6px);
    font-size: ${sizes.mobile.s16};
    letter-spacing: 0;
    color: ${color.black};
    font-family: ${font.primary};
    transform: skewX(12deg);
    padding: 14px 25px;
    text-align: left;

    &::placeholder {
        text-align: left;
        color: ${color.grey};
    }

    @media ${mediaQuery.minLaptop} {
        font-size: ${sizes.desktop.s24};
        padding: 30px 40px;
        width: calc(100% - 30px);
    }
`;

const InputWrapper = styled.div`
    position: relative;
    transform: skewX(-12deg);
    border: 1px solid ${color.grey};
    transition: border-color .2s ease;

    &.error {
        border-color: ${color.red};
    }

    &.focus {
        border-color: ${color.black};
    }
`;

const Label = styled.label`
    font-size: ${sizes.mobile.s16};
    line-height: 2.5em;
    letter-spacing: 0;
    text-align: left;
    position: relative;
    left: -6px;

    @media ${mediaQuery.minLaptop} { 
        font-size: ${sizes.desktop.s24};
        line-height: 2.5em;
        left: -10px;
    }
`;

const Error = styled.div`
    font-size: ${sizes.mobile.s12};
    line-height: 2.5em;
    letter-spacing: 0;
    color: ${color.red};
    text-align: left;
    
    position: absolute;
    top: 100%;
    left: 0;

    @media ${mediaQuery.minLaptop} { 
        font-size: ${sizes.desktop.s20};
        line-height: 2em;
    }
`;

const Message = styled.div`
    font-size: ${sizes.mobile.s12};
    line-height: 2.5em;
    letter-spacing: 0;
    color: ${color.greyDarker};
    text-align: left;

    position: absolute;
    top: 100%;
    left: 0;

    @media ${mediaQuery.minLaptop} { 
        font-size: ${sizes.desktop.s20};
        line-height: 2em;
    }
`;

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    font-family: ${font.primary};
    padding: 0 6px;

    @media ${mediaQuery.minLaptop} {
        padding: 0 10px;
    }
`;

export default {
    Wrapper,
    Input,
    Label,
    Error,
    Message,
    InputWrapper
};
