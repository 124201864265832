import styled, { css } from "styled-components";
import { color, font, mediaQuery, sizes } from "../../../../styles";
import { List as DropdownList, Wrapper as DropdownWrapper } from "../CitySelector/Dropdown/styles";

const Wrapper = styled.div<{ open: boolean }>`
    font-size: ${sizes.desktop.s20};
    letter-spacing: -0.055em;
    text-transform: uppercase;
    cursor: pointer;

    ${DropdownList} {
        top: 44px;
        right: -16px;
        min-width: auto;
        height: auto;

        @media ${mediaQuery.minLaptopL} {
            top: 51px;
        }

        @media screen and (min-width: 1920px) {
            top: 58px;
        }

        @media ${mediaQuery.maxLaptop} {
            background-color: rgba(0,0,0,0.8);
            height: 100%;
            top: 0;
        }
    }

    ${DropdownWrapper} {
        @media ${mediaQuery.maxLaptop} {
            bottom: 0;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
    }

    @media ${mediaQuery.minLaptop} {
        margin-right: 20px;
        font-size: ${sizes.desktop.s15};
    }

    @media ${mediaQuery.minLaptopL} {
        font-size: ${sizes.desktop.s16};
    }

    @media screen and (min-width: 1900px) {
        font-size: ${sizes.desktop.s20};
    }
`;

const SelectLabel = styled.div<{ open: boolean }>`
    font-family: ${font.primary};
    color: var(--white);
    text-align: center;

    @media ${mediaQuery.minLaptop} {
      font-size: ${sizes.desktop.s15};
    }

    @media ${mediaQuery.minLaptopL} {
      font-size: ${sizes.desktop.s16};
    }

    @media screen and (min-width: 1900px) {
      font-size: ${sizes.desktop.s20};
    }

    @media ${mediaQuery.maxLaptop} {
        font-family: ${font.primaryEBold};
        font-size: ${sizes.mobile.s16};
        text-align: left;
        position: relative;
    }

    &:focus, &:hover {
        color: var(--red);
    }

    &:after {
        content: "\\25BE";
        position: absolute;
        top: 2px;
        right: -10px;
        font-size: ${sizes.desktop.s10};

        @media ${mediaQuery.maxLaptop} {
            color: var(--greyDarker);
            font-size: 20px;
            top: -2px;
            right: 0;
            left: 40px;
        }

        @media ${mediaQuery.maxTablet} {
            left: 28px;
            top: -5px;
        }

        ${({ open }) => open && css`
            @media ${mediaQuery.minLaptop} {
                top: 4px;
                transform: rotatex(180deg);
            }
        `}

        @media ${mediaQuery.maxLaptop} {
            display: none;
        }
    }

    div {
        @media ${mediaQuery.maxLaptop} {
            line-height: normal;
            margin: 5px 0 0;
        }

        &:after {
            @media ${mediaQuery.maxLaptop} {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 7px 6px 0 6px;
                border-color: var(--greyDarker) transparent transparent transparent;
                position: absolute;
                top: 8px;
                left: 40px;
            }

            @media ${mediaQuery.maxTablet} {
                top: 5px;
                left: 25px;
            }
        }
    }
`;

const List = styled.div`
    background: var(--white);
`;

const ListItem = styled.div<{ active: boolean }>`
    font-family: ${font.primaryEBold};
    padding: 24px 40px;
    border-bottom: 1px solid var(--greyLight);

    @media ${mediaQuery.minLaptop} {
        font-size: ${sizes.desktop.s15};
    }
    
    @media ${mediaQuery.minLaptopL} {
        font-size: ${sizes.desktop.s16};
        letter-spacing: -0.015em;
    }

    @media screen and (min-width: 1600px) {
        font-size: ${sizes.desktop.s16};
    }

    @media screen and (min-width: 1900px) {
        font-size: ${sizes.desktop.s20};
    }

    @media ${mediaQuery.maxLaptop} {
        font-size: ${sizes.mobile.s18};
    }

    ${({ active }) => active && css`
        color: var(--red);
    `}

    &:focus, &:hover {
        color: var(--red);
    }
`;


const LanguageLabel = styled.label`
  font-family: ${font.primaryBold};
  font-size: ${sizes.mobile.s12};
  letter-spacing: 0;
  text-transform: uppercase;
  color: ${color.greyDarker};
`;

export default {
    Wrapper,
    SelectLabel,
    List,
    ListItem,
    LanguageLabel,
};
