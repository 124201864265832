export const font = {
  primary: "Proxima Nova Rg",
  primaryBold: "Proxima Nova Bo",
  primaryEBold: "Proxima Nova EBo",
  primaryBlack: "Proxima Nova Bl",
  primaryLight: "Proxima Nova Lt",
};

export const color = {
  // each color from the project
  black: "#000000",
  blackDarker: "#212121",
  darker: "#171717",
  dark: "#333333",
  white: "#ffffff",
  red: "#FD2E35",
  grey: "#C1C1C1",
  greyLightest: "#696969",
  greyDarker: "#999999",
  greyLight: "#E0E0E0",
  greyLighter: "#f0f0f0",
  green: "#A0C3A9",
};

export const mediaSize = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktopS: "1940px",
  desktop: "2560px",
};

export const mediaQuery = {
  mobileS: `(min-width: ${mediaSize.mobileS})`,
  minMobileM: `(min-width: ${mediaSize.mobileM})`,
  minMobileL: `(min-width: ${mediaSize.mobileL})`,
  minTablet: `(min-width: ${mediaSize.tablet})`,
  minLaptop: `(min-width: ${mediaSize.laptop})`,
  minLaptopL: `(min-width: ${mediaSize.laptopL})`,
  minDesktopS: `(min-width: ${mediaSize.desktopS})`,
  minDesktop: `(min-width: ${mediaSize.desktop})`,
  maxMobileM: `(max-width: ${`calc(${mediaSize.mobileM} - 1px)`})`,
  maxMobileL: `(max-width: ${`calc(${mediaSize.mobileL} - 1px)`})`,
  maxTablet: `(max-width: ${`calc(${mediaSize.tablet} - 1px)`})`,
  maxLaptop: `(max-width: ${`calc(${mediaSize.laptop} - 1px)`})`,
  maxLaptopL: `(max-width: ${`calc(${mediaSize.laptopL} - 1px)`})`,
  maxDesktopS: `(max-width: ${`calc(${mediaSize.desktopS} - 1px)`})`,
  maxDesktop: `(max-width: ${`calc(${mediaSize.desktop} - 1px)`})`,
};

const baseSizes = {
  desktop: 24,
  mobile: 16,
};

export const sizes = {
  desktop: {
    s310: `${310 / baseSizes.desktop}rem`,
    s240: `${240 / baseSizes.desktop}rem`,
    s180: `${180 / baseSizes.desktop}rem`,
    s170: `${170 / baseSizes.desktop}rem`,
    s140: `${140 / baseSizes.desktop}rem`,
    s132: `${132 / baseSizes.desktop}rem`,
    s130: `${130 / baseSizes.desktop}rem`,
    s125: `${125 / baseSizes.desktop}rem`,
    s120: `${120 / baseSizes.desktop}rem`,
    s100: `${100 / baseSizes.desktop}rem`,
    s80: `${80 / baseSizes.desktop}rem`,
    s70: `${70 / baseSizes.desktop}rem`,
    s68: `${68 / baseSizes.desktop}rem`,
    s60: `${60 / baseSizes.desktop}rem`,
    s58: `${58 / baseSizes.desktop}rem`,
    s53: `${53 / baseSizes.desktop}rem`,
    s50: `${50 / baseSizes.desktop}rem`,
    s46: `${46 / baseSizes.desktop}rem`,
    s44: `${44 / baseSizes.desktop}rem`,
    s40: `${40 / baseSizes.desktop}rem`,
    s36: `${36 / baseSizes.desktop}rem`,
    s34: `${34 / baseSizes.desktop}rem`,
    s32: `${32 / baseSizes.desktop}rem`,
    s30: `${30 / baseSizes.desktop}rem`,
    s28: `${28 / baseSizes.desktop}rem`,
    s26: `${26 / baseSizes.desktop}rem`,
    s24: `${24 / baseSizes.desktop}rem`,
    s22: `${22 / baseSizes.desktop}rem`,
    s20: `${20 / baseSizes.desktop}rem`,
    s19: `${19 / baseSizes.desktop}rem`,
    s18: `${18 / baseSizes.desktop}rem`,
    s17: `${17 / baseSizes.desktop}rem`,
    s16: `${16 / baseSizes.desktop}rem`,
    s15: `${15 / baseSizes.desktop}rem`,
    s14: `${14 / baseSizes.desktop}rem`,
    s13: `${13 / baseSizes.desktop}rem`,
    s12: `${12 / baseSizes.desktop}rem`,
    s10: `${10 / baseSizes.desktop}rem`,
  },
  mobile: {
    s64: `${64 / baseSizes.mobile}rem`,
    s56: `${56 / baseSizes.mobile}rem`,
    s44: `${44 / baseSizes.mobile}rem`,
    s40: `${40 / baseSizes.mobile}rem`,
    s36: `${36 / baseSizes.mobile}rem`,
    s32: `${32 / baseSizes.mobile}rem`,
    s30: `${30 / baseSizes.mobile}rem`,
    s28: `${28 / baseSizes.mobile}rem`,
    s24: `${24 / baseSizes.mobile}rem`,
    s20: `${20 / baseSizes.mobile}rem`,
    s18: `${18 / baseSizes.mobile}rem`,
    s16: `${16 / baseSizes.mobile}rem`,
    s14: `${14 / baseSizes.mobile}rem`,
    s13: `${13 / baseSizes.mobile}rem`,
    s12: `${12 / baseSizes.mobile}rem`,
    s11: `${11 / baseSizes.mobile}rem`,
  },
};
