import React, { useState, useCallback, Fragment } from "react";
import { Loading, LoadingTypes } from "../Loading";

export interface WithLoadingProps {
    setLoaded: (time?: number) => void
};

export const withLoading = <P extends WithLoadingProps>(WrappedComponent: React.ComponentType<P>): React.ComponentType<Omit<P, keyof WithLoadingProps>> => {

    const HOC: React.FC<Omit<P, keyof WithLoadingProps>> = (props) => {
        const [loading, setLoading] = useState(true);

        const setLoaded = useCallback((time?: number) => {
            if (loading) {
                setTimeout(() => {
                    setLoading(false);
                }, time || 1000);
            }
        }, [loading, setLoading]);

        return (
            <Fragment>
                <div className={`preloader ${loading ? 'visible' : ''}`}>
                    <Loading background={LoadingTypes.Black} />
                </div>
                <WrappedComponent {...props as any} setLoaded={setLoaded} />
            </Fragment>
        );
    };

    HOC.displayName = `withLoading(${WrappedComponent.displayName || WrappedComponent.name})`;
    return HOC;
};
