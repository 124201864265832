import { GymsGetAllOutput, GymsGetAllInput, GymGetScheduleInput, GymGetScheduleOutput, ClassesSimplifiedGetAllInput, ClassesSimplifiedGetAllOutput, ClassesPublicGetAllOutput, ClassesPublicGetAllInput, ClassesGetAllInput, ClassesGetAllOutput, StaffGetAllInput, StaffGetAllOutput, MembershipPlansGetAllInput, MembershipPlansGetAllOutput, MembershipExtendedPlansGetAllInput, MembershipExtendedPlansGetAllOutput, ClassesCompleteListGetAllInput, ClassesCompleteListGetAllOutput } from "./dlos";
import { Generic, DloInput } from "@7egend/web.core";
import { ContactInput, ContactOutput } from "./dlos/contact";

interface DloInputConstructor {
    new (): DloInput;
}

class ProvisServices {

    private requestCall(request: DloInput<any>) {
        const generic = new Generic();
        return generic.fw.dlo.call(request);
    }

    private async genericMethod(RequestClass: DloInputConstructor, options?: any) {
        const request = new RequestClass();
        request.body = options;
        const result = await this.requestCall(request);
        return result.body;
    }

    /**
     * Gets all Gyms
     * @param options 
     */
    public getGyms = async (options: GymsGetAllInput["body"]): Promise<GymsGetAllOutput["body"]> => this.genericMethod(GymsGetAllInput, options);

    /**
     * Gets a Gym schedule
     * @param options 
     */
    public getGymSchedule = async (options: GymGetScheduleInput["body"]): Promise<GymGetScheduleOutput["body"]> => this.genericMethod(GymGetScheduleInput, options)

    /**
     * Gets Gym Classes list
     */
    public getGymClassesList = async (options: ClassesGetAllInput["body"]): Promise<ClassesGetAllOutput["body"]> => this.genericMethod(ClassesGetAllInput, options)

    /**
     * Gets a Gym Public Classes list organized by day
     * @param options 
     */
    public getGymClassesPublic = async (options: ClassesPublicGetAllInput["body"]): Promise<ClassesPublicGetAllOutput["body"]> => this.genericMethod(ClassesPublicGetAllInput, options)

    /** Gets Gym Classes list */
    public getGymClassesSimplifiedList = async (options?: ClassesSimplifiedGetAllInput["body"]): Promise<ClassesSimplifiedGetAllOutput["body"]> => this.genericMethod(ClassesSimplifiedGetAllInput, options)

    /** Gets Complete Classes list */
    public getGymClassesCompleteList = async (options?: ClassesCompleteListGetAllInput["body"]): Promise<ClassesCompleteListGetAllOutput["body"]> => this.genericMethod(ClassesCompleteListGetAllInput, options)

    /** Gets the staff list */
    public getStaffList = async (options: StaffGetAllInput["body"]): Promise<StaffGetAllOutput["body"]> => this.genericMethod(StaffGetAllInput, options)
    
    /** Send Contact Form */
    public postContactForm = async (options: ContactInput["body"]): Promise<ContactOutput["body"]> => this.genericMethod(ContactInput, options)

    /**
     * Gets Membership Plans
     * @param options
     */
    public getMembershipPlans = async (options: MembershipPlansGetAllInput["body"]): Promise<MembershipPlansGetAllOutput["body"]> => this.genericMethod(MembershipPlansGetAllInput, options)

    /**
     * Gets Subscription Vouchers and Subscriptions
     * @param options
     */
    public getMembershipExtendedPlans = async (options: MembershipExtendedPlansGetAllInput["body"]): Promise<MembershipExtendedPlansGetAllOutput["body"]> => this.genericMethod(MembershipExtendedPlansGetAllInput, options)
}

export const provisServices = new ProvisServices();
