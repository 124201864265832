import React, { useState, useCallback, FormEvent, useEffect } from "react";
import Styles from "./styles";
import { Link, Target } from "@7egend/web.core.controls/lib/components/link";
import { useI18n } from "@7egend/web.core/lib/components/useI18n";
import { Config } from "../../domain/config";
import { useFramework } from "@7egend/web.core/lib/components/useFramework";
import { Link as LinkComponent, LinkSize, LinkVariant } from "../../components/Link";
import { withMenu, WithMenuProps } from "@7egend/web.core.cms/lib/components/withMenu";
import { Menu } from "@7egend/web.core.cms/lib/dlos/menu";
import { InstagramFeed } from "../InstagramFeed";
import { WithForm, WithFormProps } from "@7egend/web.core.forms/lib/components/withForm";
import { ReactComponent as LogoSVG } from "../../logo.svg";

const Footer: React.FC<WithMenuProps & WithFormProps> = props => {
  const [translate] = useI18n();
  const [email, setEmail] = useState<string>("");
  const [menu, setMenu] = useState<Menu[]>();
  const [loading, setLoading] = React.useState<boolean>(false); /* eslint-disable-line */
  const [error, setError] = React.useState<boolean | undefined>();
  const [formValid, setFormValid] = React.useState<boolean>(false);
  const fw = useFramework();

  const onEmailChange = useCallback((value: string, name: string, valid: boolean) => { /* eslint-disable-line */
    // set form validation
    setFormValid(valid);
    // set email
    setEmail(value);
  }, []);

  const onNewsletterSubscribe = useCallback((e: FormEvent) => { /* eslint-disable-line */
    e.preventDefault();
    setLoading(true);

    if (!email || !formValid) {
      return;
    }

    const data = [
      {
        input: "3",
        value: email
      }
    ];

    props.submitForm((fw.config as Config).newsletterSecret, data)
      .then(res => {
        setError(false);
        setLoading(false);
      })
      .catch(err => {
        setError(true);
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    let menuArray: Array<Menu> = [];

    if (props.menu.data) {
      props.menu.data.forEach(item => {
        if (item.menus && item.menus.length > 0) {
          item.menus.forEach(subItem => menuArray.push(subItem));
        } else {
          menuArray.push(item);
        }
      });

      setMenu(menuArray);
    }
  }, [props.menu.data]);

  useEffect(() => {
    if (error === false) {
      setTimeout(() => {
        setError(undefined);
        if (formValid) {
          setEmail("");
        }
      }, 3000);
    }
  });

  // List of exceptions to hide from menu
  const menuExceptions = useCallback((exception: Menu) => (exception.path !== "/pages/privacy-policy" && exception.path !== "/pages/terms-conditions" && exception.path !== "/pages/covid"), []);

  return (
    <Styles.Footer>
      <div className="container">
        <Styles.ContentRow>
          <Styles.ContentColumn>
            <Styles.ContentTitle className="links">
              {translate("links")}
            </Styles.ContentTitle>
            <div className="row">
              <div className="col-6">
                <Styles.FooterLinks>
                  <Styles.FooterLink
                    // @ts-ignore
                    exact={true}
                    onClick={() => window.scrollTo({ top: 0 })}
                    key="home"
                    href="/"
                  >{translate("home")}</Styles.FooterLink>
                  {menu && menu.filter(menuExceptions).map(item => (
                    <Styles.FooterLink onClick={() => window.scrollTo({ top: 0 })} key={item.id || item.path} href={item.path}>{item.name}</Styles.FooterLink>
                  ))}
                </Styles.FooterLinks>
              </div>
              <div className="col-6">
                <Styles.FooterLinks>
                  <Styles.FooterLink
                    onClick={() => window.scrollTo({ top: 0 })}
                    key="free-day-pass"
                    href="free-day-pass"
                  >{translate("get.freeday.pass")}</Styles.FooterLink>
                  <Styles.FooterLink
                    onClick={() => window.scrollTo({ top: 0 })}
                    key="join"
                    href={"join-us"}
                  >{translate("join.now")}</Styles.FooterLink>
                  <Styles.FooterLink
                    onClick={() => window.scrollTo({ top: 0 })}
                    key="timetables"
                    href="timetables"
                  >{translate("timetables")}</Styles.FooterLink>
                  {menu && menu.filter(filter => (filter.path === "/pages/terms-conditions" || filter.path === "/pages/privacy-policy")).map(item => (
                    <Styles.FooterLink onClick={() => window.scrollTo({ top: 0 })} key={item.id || item.path} href={item.path}>{item.name}</Styles.FooterLink>
                  ))}
                </Styles.FooterLinks>
              </div>
            </div>
          </Styles.ContentColumn>

          <Styles.ContentColumn>
            <Styles.ContentTitle>
              {translate("our.instagram")}
            </Styles.ContentTitle>
            <InstagramFeed />
          </Styles.ContentColumn>

          <Styles.ContentColumn>
            <Styles.SocialNetworks>
              {(fw.config as Config).social.facebook && (
                <Styles.SocialLink href={(fw.config as Config).social.facebook} target={Target.blank}><i className="icon-facebook"></i></Styles.SocialLink>
              )}

              {(fw.config as Config).social.instagram && (
                <Styles.SocialLink href={(fw.config as Config).social.instagram} target={Target.blank}><i className="icon-instagram"></i></Styles.SocialLink>
              )}

              {(fw.config as Config).social.twitter && (
                <Styles.SocialLink href={(fw.config as Config).social.twitter} target={Target.blank}><i className="icon-twitter"></i></Styles.SocialLink>
              )}

              {/* {(fw.config as Config).social.snapchat && (
                <Styles.SocialLink href={(fw.config as Config).social.snapchat} target={Target.blank}><i className="icon-snapchat"></i></Styles.SocialLink>
              )} */}
            </Styles.SocialNetworks>
            {/* <Styles.Newsletter autoComplete={"off"} onSubmit={onNewsletterSubscribe}>
              <Styles.Title>{translate("newsletter.title")}</Styles.Title>
              <Styles.NewsletterInputs>
                <Styles.NewsletterInput
                  name="subscribe"
                  placeholder={translate("newsletter.input.email")}
                  value={email}
                  required
                  onChange={onEmailChange}
                  type="email"
                />
                <Styles.NewsletterButton type="button" onClick={onNewsletterSubscribe}>
                  <i className="icon-arrow-right"></i>
                </Styles.NewsletterButton>
              </Styles.NewsletterInputs>
              <Message error={error}>
                {error && !loading ? translate("form.message.error") : error === false && !loading ? translate("form.message.success") : ""}
              </Message>
            </Styles.Newsletter> */}
            <Styles.Title>{translate("interested.franchise")}</Styles.Title>
            <Styles.Contact>
              <LinkComponent onClick={() => window.scrollTo({ top: 0 })} className="contact-us" variant={LinkVariant.WhiteBlack} size={LinkSize.Medium} href={"franchise"}>{translate("contact.us")}</LinkComponent>
            </Styles.Contact>
          </Styles.ContentColumn>

        </Styles.ContentRow>
        <Styles.FooterCopyright>
          <Styles.Logo onClick={() => window.scrollTo({ top: 0 })} href="/">
            <LogoSVG />
          </Styles.Logo>
          <span className="rights">
            {translate("copyright.fitness", {
              year: new Date().getFullYear().toString()
            })}
          </span>
          <Link target={Target.blank} href="https://www.7egend.cr">{`${translate("made.by")} `} <b>7EGEND</b></Link>
        </Styles.FooterCopyright>
      </div>
    </Styles.Footer>
  );
};

export default withMenu(WithForm(Footer));
